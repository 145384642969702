import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import { toast } from 'react-toastify';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useBeforeunload } from "react-beforeunload";
import _ from "lodash";
import {
  CssBaseline,
  Typography,
  Container,
  Box,
  TextField,
  FormControlLabel,
  Radio,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GRAPH_URL } from "../../../actions/constant";
import Loading from "../../../components/Loading";
import Modal from "../../../components/Modal";
import { getWorksheetContent, startSubmitQuiz } from "../../../actions/worksheet";
import { getGame } from "../../../actions/games";
import { checkSubscriptionType } from "../../../actions/tutor";
import { useSelector } from "react-redux";

import {
  isIOS,
} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  main: {
    display: "inline-grid",
  },
  header: {
    boxShadow: "0px 1px 11px #e0e0e0",
    padding: "20px",
    marginBottom: 20,
    backgroundColor: "white",
    justifyContent: "center",
    alignSelf: "center",
    textDecoration: "none",
    maxWidth: "inherit"
  },
  name: {
    display: "flex",
    marginTop: 10,
    backgroundColor: "#F1F1F1",
    borderRadius: 5,
    padding: 8,
  },
  nameText: {
    color: theme.palette.secondary.main,
    marginLeft: 5,
    fontWeight: "bold",
  },
  logo: {
    maxHeight: 40,
    verticalAlign: "middle",
    marginRight: "5px",
  },
  body: {
    borderTop: "1px solid #dedcdc",
    marginBottom: 50,
    marginTop: 30
  },
  content: {
    backgroundColor: "#fff",
    boxShadow: "0px 2px 4px #dadada",
    padding: "40px 30px",
    borderRadius: 5
  },
  icon: {
    fontSize: 56,
    marginBottom: 10,
    color: theme.palette.primary.main,
  },
  radio: {
    display: "block",
    color: "#737373"
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  selectRoot: {
    '&:focus': {
      backgroundColor: 'inherit',
      color: "#757575",
    }
  },
  feedback: {
    width: "100%",
    textAlign: "center",
    borderRadius: "4px",
    padding: "10px 0",
    fontSize: "14px",
    color: "white",
    marginBottom: 10,
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    width: 160,
    color: "#fff",
    textTransform: "capitalize"
  },
  pageTab: {
    padding: 10,
    marginRight: 10,
  },
  addPageIcon: {
    alignSelf: "center",
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  isTabActive: {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },
  question: {
    marginBottom: 5,
    fontWeight: 700,
    color: "#363636"
  },
  submitIcon: {
    fontSize: 80,
    color: theme.palette.primary.main,
    marginBottom: 40
  },
  accordion: {
    marginBottom: "30px !important",
    boxShadow: "0px 1px 2px #dadada"
  }
}));

const ViewWorksheetPage = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [content, setContent] = useState([[]]);
  const [studentName, setStudentName] = useState("");
  const [classCode, setClassCode] = useState("");
  const [gameCode, setGameCode] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const { worksheetId, tutorId, studentId } = props.match.params;
  const [graphsHeight, setGraphsHeight] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const [googlePlayStoreURL, setGooglePlayStoreURL] = useState("");
  const [appStoreURL, setAppStoreURL] = useState("");
  const [gameLink, setGameLink] = useState("");
  const [gameIcon, setGameIcon] = useState("");
  const [deviceType, setDeviceType] = useState('browser');

  const [nextCount, setNextCount] = useState(0);

  const tutor = useSelector((state) => state.tutor.tutor);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    // Existing checks for iOS and Android devices
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceType('iOS');
    } else if (/android/i.test(userAgent)) {
      setDeviceType('Android');
    } else {
      // Enhanced iOS 13+ detection logic
      if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)) {
        setDeviceType('iOS');
      } else {
        // Default to 'browser' if none of the above conditions are met
        setDeviceType('browser');
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      const game = await getGame(gameCode);
      if (game) {
        setGooglePlayStoreURL(game.googlePlayStoreURL || "");
        setAppStoreURL(game.appStoreURL || "");
        setGameLink(game.gameLink || "");
        setGameIcon(game.icon || "");
      }
    })();
  }, [gameCode]);
  


  useEffect(() => {
    (async () => {
      let data = [];
      const res = await getWorksheetContent(worksheetId, tutorId, studentId);
      if (!res) {
        return;
      }

      data = JSON.parse(res.content).map((page) => {
        return page.map((block) => {
          if (block.type === "graph") {
            return {
              ...block,
              graphUrl: `${GRAPH_URL}/embed/${res.classCode}&${res.gameCode}&${block.value}&${worksheetId}${tutor.subscriptionType.startsWith("platinum") ? "&true" : "&false"}`
            }
          };
          return block
        })
      });

      window.addEventListener("message", (event) => {

        if (event.data.height) {
          setGraphsHeight((prevState) => ({
            ...prevState,
            [`${event.data.gameCode}${event.data.chartId}`]: event.data.height
          }))
        }
      });

      setContent(data);
      setStudentName(res.studentName);
      setClassCode(res.classCode);
      setClassCode(res.classCode);
      setGameCode(res.gameCode);
      setLoading(false);
    })()
  }, [worksheetId, tutorId, studentId]);

  const handleSetContent = (data, index, optionsIndex) => {
    let updatedList = content.map((item, i) => {
      if (activeTab === i) {
        item[index].value = data.value;
        item[index].color = 'transparent';
      }
      return item
    });

    setContent(updatedList);
  };

  const handleNextPage = () => {
    for (let i = 0; i < content[activeTab].length; i++) {
      if (!content[activeTab][i].value) {
        toast.error("Please make sure to fill all fields before proceeding to next page")
        setNextCount(nextCount + 1);
        break;
      } else if (content[activeTab].length === i + 1) {
        setActiveTab(activeTab + 1)
        window.scrollTo(0, 0)
      }
    };
  };

  const handleBackPage = () => {
    setActiveTab(activeTab - 1)
    window.scrollTo(0, 0)
  };

  const handleSubmit = async () => {
    for (let i = 0; i < content[activeTab].length; i++) {
      if (!content[activeTab][i].value) {
        toast.error("Please make sure to fill all fields before submitting")
        setNextCount(nextCount + 1);
        break;
      } else if (content[activeTab].length === i + 1) {
        setOpen(true);
        const data = _.flatMap(content).filter((item) => item.type !== "html" && item.type !== "graph" && item.type !== "accordion").map((item) => ({ question: item.question, answer: item.value }))
        await startSubmitQuiz(tutorId, worksheetId, studentId, JSON.stringify(data));
        setSubmitted(true);
        setOpen(false);
      }
    };
  };

  useEffect(() => {
    // Create a deep copy of content to avoid direct mutation
    const updatedContent = content.map((tab, tabIndex) => {
      if (activeTab === tabIndex) {
        return tab.map(item => ({
          ...item,
          color: item.value ? 'transparent' : '#E8FBF9',
        }));
      }
      return tab;
    });
  
    // Update the state once with all changes
    setContent(updatedContent);
  }, [nextCount]);


  // Show students dialog to prevent them from refreshing the page before submitting their quiz
  useBeforeunload((event) => {
    if (!submitted) {
      event.preventDefault();
    }
  });

  return (
    loading ?
      <Loading width="full" />
      :
      <Container maxWidth={false} style={{ padding: 0 }} >
        <Container className={classes.header}>
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ color: "#757575", textAlign: "center" }}
          >
            <img src="/img/arludo_logo.png" alt="Arludo Logo" className={classes.logo} />
            Arludo
          </Typography>
        </Container>
        {submitted ?
          <Box style={{ display: "block", textAlign: "center", marginTop: 100 }} >
            <DoneIcon className={classes.submitIcon} />
            <Typography
              variant="h5"
              color="textSecondary"
            >
              Thank you for completing this quiz. You can close the page now.
            </Typography>
          </Box>
          :
          <Container maxWidth="md">
            <CssBaseline />
            <Box className={classes.main}>
              <Box className={classes.name}>
                <Typography
                  color="textSecondary"
                  style={{ fontWeight: "bold" }}
                >
                  Class Code:
                </Typography>
                <Typography
                  color="textSecondary"
                  className={classes.nameText}
                >
                  {classCode}
                </Typography>
              </Box>
              <Box className={classes.name}>
                <Typography
                  color="textSecondary"
                  style={{ fontWeight: "bold" }}
                >
                  Student ID:
                </Typography>
                <Typography
                  color="textSecondary"
                  className={classes.nameText}
                >
                  {studentName}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.body}>

              <Box style={{ marginBottom: 30, borderBottom: "1px solid #dedcdc" }}>
                <Box style={{ display: "flex", margin: "10px 0" }}>
                  {content.map((item, i) => (
                    <Typography
                      color="textSecondary"
                      key={i}
                      className={`${classes.pageTab} ${i === activeTab ? classes.isTabActive : ""}`}
                    >
                      Page {i + 1}
                    </Typography>

                  ))}
                </Box>
                <Box className={classes.content}>
                  {content[activeTab].map((item, index) => (
                    <Box key={index} style={{backgroundColor: content[activeTab][index].color}}>
                      {item.type === "gameEmbed" &&
                        <Box>
                          {isIOS && (
                            <div>
                              <Typography> 
                                To enjoy the best experience, please download the app to your device using the button below.
                              </Typography>
                            
                              <a href={appStoreURL} target="_blank" rel="noopener noreferrer">
                                <img
                                  src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                                  alt="Download on the App Store"
                                  style={{ width: 150, height: 'auto' }}
                                />
                              </a>
                              <a href={gameLink+ `?class=${classCode}&name=${studentName}`} target="_blank" rel="noopener noreferrer">
                                <Typography>
                                  I know I'm on an iPad, but I want to play the game in browser anyway
                                </Typography>
                              </a>
                            </div>
                          )}
                          {deviceType === "Android" && (
                            
                            <div>
                              <Typography> 
                                To enjoy the best experience, please download the app to your device using the button below.
                              </Typography>
                              <a href={googlePlayStoreURL} target="_blank" rel="noopener noreferrer">
                                <img
                                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                  alt="Get it on Google Play"
                                  style={{ width: 150, height: 'auto' }}
                                />
                              </a>
                              <a href={gameLink+ `?class=${classCode}&name=${studentName}`} target="_blank" rel="noopener noreferrer">
                                <Typography>
                                  I know I'm on an Android, but I want to play the game in browser anyway
                                </Typography>
                              </a>
                            </div>
                          )}
                          {deviceType === "browser" && (
                            <a href={gameLink+ `?class=${classCode}&name=${studentName}`} target="_blank" rel="noopener noreferrer">
                              <img
                                src={gameIcon}
                                alt="play the game in browser"
                                style={{ width: 150, height: 'auto', borderRadius: '20px' }}
                              />
                              <Typography>
                                Play the game in the browser
                              </Typography>
                            </a>
                          )}
                          
                        </Box>
                      }
                      {item.type === "html" &&
                        <Box style={{ marginBottom: 30 }}>
                          {parse(`${item.value}`, { trim: true })}
                        </Box>
                      }
                      {item.type === "accordion" &&
                        <Accordion className={classes.accordion}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <Typography>{item.question}</Typography>
                          </AccordionSummary>
                          <AccordionDetails style={{ display: "block" }}>
                            {parse(`${item.value}`, { trim: true })}
                          </AccordionDetails>
                        </Accordion>
                      }
                      {item.type === "graph" &&
                        <Box style={{ marginBottom: 30 }}>
                          <center>
                            <iframe
                              title={`${gameCode}${item.value}`}
                              src={item.graphUrl}
                              width="90%"
                              height={graphsHeight[`${gameCode}${item.value}`]}
                              style={{ border: 0 }}
                              allowFullScreen
                            ></iframe>
                          </center>
                        </Box>
                      }
                      {item.type === "sa" &&
                        <Box style={{ marginBottom: 30 }}>
                          <Typography
                            className={classes.question}
                          >
                            {item.question}
                          </Typography>
                          <TextField
                            variant="outlined"
                            placeholder="Answer..."
                            size="small"
                            fullWidth
                            id="question"
                            value={item.value}
                            onChange={(e) => handleSetContent(e.target, index)}
                          />
                        </Box>
                      }
                      {item.type === "la" &&
                        <Box style={{ marginBottom: 30 }}>
                          <Typography
                            className={classes.question}
                          >
                            {item.question}
                          </Typography>
                          <TextField
                            variant="outlined"
                            placeholder="Answer..."
                            size="small"
                            multiline
                            rows={8}
                            fullWidth
                            id="value"
                            value={item.value}
                            onChange={(e) => handleSetContent(e.target, index)}
                          />
                        </Box>
                      }
                      {item.type === "mc" &&
                        <Box style={{ marginBottom: 30 }}>
                          {item.showFeedback && item.value && <Typography
                            className={classes.feedback}
                            style={{ backgroundColor: item.value === item.correctOption ? theme.palette.primary.main : theme.palette.text.secondary }}
                          >
                            {item.feedback[item.options.indexOf(item.value)]}
                          </Typography>}
                          <Typography
                            className={classes.question}
                          >
                            {item.question}
                          </Typography>
                          {item.options.map((option, i) => (
                            <FormControlLabel
                              key={i}
                              control={
                                <Radio
                                  size="small"
                                  color="primary"
                                  id="radio"
                                  checked={item.value === option}
                                  onChange={(e) => handleSetContent(e.target, index)}
                                  value={`${option}`}
                                />
                              }
                              label={`${option}`}
                              className={classes.radio}
                            />
                          ))}
                        </Box>
                      }

                    </Box>
                  ))}
                  <Box style={{ display: "flex", justifyContent: "space-around" }}>
                    {activeTab > 0 &&
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleBackPage}
                      >
                        Back
                      </Button>
                    }
                    {activeTab < content.length - 1 &&
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleNextPage}
                      >
                        Next Page
                      </Button>
                    }
                    {activeTab === content.length - 1 &&
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    }
                  </Box>
                </Box>
              </Box>

            </Box>
            <Modal open={open} />
          </Container>
        }
      </Container>
  )
};

export default ViewWorksheetPage;