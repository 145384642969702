import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { checkCoupon, createSubscription, startSetTutorData } from '../../../actions/tutor';
import { plans } from './constants/plans';

import './subscription.scss';

function capitalize(str) {
  if (!str) return ""
  return str.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
}

const Subscription = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedPlan, setSelectedPlan] = useState('');
  // const [cardNumberComplete, setCardNumberComplete] = useState(true);
  // const [expDateComplete, setExpDateComplete] = useState(true);
  const [loading, setLoading] = useState(false);
  const { tutor } = useSelector((state) => state.tutor);
  const [showOverlay, setOverlay] = useState(false);
  const [numYears, setNumYears] = useState(1);

  const [formData, setFormData] = useState({
    zip: "",
    name: "",
    coupon: "",
    couponDetails: null,
    email: tutor.email,
    error: false,
  })

  const stripe = useStripe();
  const stripeTestMode = stripe._keyMode === "test";
  const elements = useElements();

  const [showYearlyList, setShowYearlyList] = useState(true)
  const [planList, setPlanList] = useState([])


  const checkCouponValidity = async () => {
    try {
      const couponData = await checkCoupon(formData.coupon, selectedPlan);

      setFormData({
        ...formData,
        couponDetails: couponData,
        error: !!couponData.errors || couponData.error
      })

      if (!!couponData.error) {
        throw new Error(couponData.error.message)
      }
      toast.success('Coupon Code Successfully Applied');
    } catch (error) {
      toast.error('Coupon Code Not Valid: ' + error.message);
    }
  }

  const handleOnSubmitClick = async (e) => {
    e.preventDefault();

    try {
      if (!stripe || !elements) return;


      setLoading(true);
      const paymentMethod = await stripe?.createPaymentMethod({
        type: chosenPlanCost.total() === "0.00" ? 'customer_balance' : 'card',
        card: chosenPlanCost.total() === "0.00" ? undefined : elements.getElement(CardNumberElement),

        billing_details: {
          name: formData.name,
          email: formData.email,
          address: {
            postal_code: formData.zip
          },
        }
      });

      if (paymentMethod.error) {
        toast.error("Something went wrong! " + paymentMethod.error.message);
        setLoading(false);
        return;
      }

      const subscriptionData = await createSubscription({
        paymentMethodId: paymentMethod.paymentMethod.id,
        priceId: selectedPlan,
        couponId: formData.couponDetails?.id,
      });


      if (subscriptionData.error) {
        toast.error("Something went wrong! " + subscriptionData.error.message);
        setLoading(false);
        return;
      }

      if (subscriptionData.paid || subscriptionData.clientSecret) {
        if (subscriptionData.clientSecret) {
          const confirmPayment = await stripe?.confirmCardPayment(subscriptionData.clientSecret);

          if (confirmPayment.error) {
            setFormData({ ...formData, error: confirmPayment.error.message });
            toast.error(confirmPayment.error.message);
            return setLoading(false);
          }
        }
        setFormData({ ...formData, error: false });
        dispatch(startSetTutorData({
          isFirstTimeSubscribed: true,
          subscribedPlan: selectedPlan
        }));

        toast.success('Subscription successful!');
        history.push('/');

      } else {
        toast.error("Something went wrong! " + subscriptionData.error.message);
        setLoading(false);
      }

    } catch (error) {
      toast.error("Something went wrong here! " + error.message);
      setLoading(false);
    }
  };


  useEffect(() => {
    const newPlanList = []
    for (const plan in plans) {
      newPlanList.push(plans[plan]["monthly"])
      newPlanList.push(plans[plan]["annual"])
    }
    for (const plan in newPlanList) {
      newPlanList[plan].disabled = !(newPlanList[plan].frequency === (showYearlyList ? "annual" : "monthly"))
    }


    setPlanList(newPlanList)
    if (selectedPlan && newPlanList.find(p => p.priceId === selectedPlan).couponCode) {
      setFormData(f => ({ ...f, coupon: newPlanList.find(p => p.priceId === selectedPlan).couponCode }))
    }

    if (newPlanList.find(p => p.priceId === selectedPlan)?.disabled) {
      setSelectedPlan(newPlanList.find(p => !p.disabled).priceId)
    }
  }, [selectedPlan, showYearlyList]);

  const chosenPlan = planList.find(p => p.priceId === selectedPlan) || {
    name: "",
    cost: 0.1,
  }

  const chosenPlanCost = {
    original: chosenPlan.cost * (chosenPlan.frequency === "annual" ? 12 : 1),
    monthly: () => {
      var cost = chosenPlan.cost || 0.1

      if (formData.couponDetails?.amount_off) {
        if (chosenPlan.frequency === "annual") {
          cost -= formData.couponDetails.amount_off / 100 / 12
        } else {
          cost -= formData.couponDetails.amount_off / 100
        }
      }

      if (formData.couponDetails?.percent_off) {
        cost *= (1 - (formData.couponDetails.percent_off / 100))
      }

      return parseFloat(cost).toFixed(2)
    },
    total: () => {
      var cost = (chosenPlan.cost * (chosenPlan.frequency === "annual" ? 12 : 1)) || 0.1

      if (formData.couponDetails?.amount_off) {
        cost -= formData.couponDetails.amount_off / 100
      }

      if (formData.couponDetails?.percent_off) {
        cost *= (1 - (formData.couponDetails.percent_off / 100))
      }

      return parseFloat(cost).toFixed(2)
    }
  }

  return (
    <div className="subscription">
      <div className="subscription_plans">
        <h1 className="">Plans and Pricing</h1>
        <h3>Included in every plan</h3>
        <div className="subscription_plans_featuresIcons">
          <div>
            <img src="https://arludo.com/wp-content/uploads/2023/10/Pricing_icons-worksheet.webp" alt="Worksheets" />
            <span>Worksheets</span>
          </div>
          <div>
            <img src="https://arludo.com/wp-content/uploads/2023/10/Pricing_icons-games.webp" alt="Games" />
            <span>Games</span>
          </div>
          <div>
            <img src="https://arludo.com/wp-content/uploads/2023/10/Pricing_icons-graphss-04.webp" alt="Data" />
            <span>Data</span>
          </div>
          <div>
            <img src="https://arludo.com/wp-content/uploads/2023/10/Pricing_icons-data.webp" alt="Graphs" />
            <span>Graphs</span>
          </div>
          <div>
            <img src="https://arludo.com/wp-content/uploads/2023/10/Pricing_icons-students.webp" alt="Unlimited Students" />
            <span>Unlimited Students</span>
          </div>
        </div>
        <h3>Yearly Subscribers Save <span>25%</span> and gain other benefits.</h3>
        <div className='subscription_plans_frequencySelector'>
          <button className={(!showYearlyList && "active").toString()} onClick={() => setShowYearlyList(false)}>Monthly</button>
          <button className={(showYearlyList && "active").toString()} onClick={() => setShowYearlyList(true)}>Annual</button>

        </div>
        <div className="subscription_plans_list">
          {
            planList.map(plan => (
              <div className={`plan ${selectedPlan === plan.priceId && "active"}`} key={plan.priceId}
                style={{
                  border: selectedPlan === plan.priceId && `2px solid ${plan.color}`,
                  filter: plan.disabled && "opacity(0.3)",
                  marginBottom: "20px",
                }}
              >
                <h2
                  className='title'
                  style={{
                    background: plan.disabled ? "#999" : plan.color
                  }}
                >Arludo<br />{plan.name}</h2>
                <h4
                  className='title'
                  style={{
                    background: plan.disabled ? "#999" : plan.color
                  }}
                >{plan.frequency}</h4>
                <ul className='features'>
                  {
                    plan.features.map(f => (
                      <li key={f}>{f}</li>
                    ))
                  }
                </ul>

                <div
                  className="pricing"
                  style={{
                    background: plan.disabled ? "#999" : plan.color
                  }}
                >
                  <span className="cost">${Math.round(plan.cost * (showYearlyList ? 12 : 1) * 100) / 100} / {showYearlyList ? "year" : "month"}</span>
                  <span className="pricing_details ">for <b>{capitalize(plan?.pricingDetails)}</b></span>
                </div>

                <ul className='featuresPlus'>
                  {
                    plan.featuresPlus.map(f => (
                      <li key={f}>{f}</li>
                    ))
                  }
                </ul>
                <button
                  className='submit'
                  onClick={() => setSelectedPlan(plan.priceId)}
                  style={{
                    background: plan.disabled ? "#999" : plan.color,
                    display: !plan.disabled ? "inherit" : "none",
                  }}
                >Select{plan.priceId === selectedPlan && "ed"}</button>
              </div>
            ))
          }
        </div>
      </div>

      <button
        type="submit"
        className="submit-button pay-now"
        onClick={() => setOverlay(true)}
        disabled={!selectedPlan}
      >Pay Now</button>


      <div className={`payment_overlay ${showOverlay && "active"}`}>
        <div className="payment_overlay_box">
          <div className="payment_overlay_summary_header">

            <button
              className='return-back-button'
              onClick={() => setOverlay(false)}
            ><ArrowBackIcon /></button>
            <img src="/img/arludo_logo_horizontal_notagline_trans_white.png" alt="" className="arludo-logo" />
          </div>
          <div className="payment_overlay_summary">
            <div className="payment_overlay_summary_item">
              <span className="plan_name">Subscribe to the Arludo {capitalize(`${chosenPlan.name}`)} Plan</span>
              <div className="payment_cost">
                <span className="payment_amount">${chosenPlanCost.monthly()}</span>
                <div className='alt-text'>
                  <span>per</span>
                  <span>month</span>
                </div>
              </div>
            </div>
            <div className="payment_overlay_summary_details">
              <div className="plan_header">
                <span className="plan_header_title">Arludo {capitalize(`${chosenPlan.name} ${chosenPlan.frequency}`)}</span>
                <span className="plan_header_details">Billed {capitalize(chosenPlan.frequency === "annual" ? "annually" : chosenPlan.frequency)}</span>
              </div>
              <span className="plan_cost">Total Charged:
                ${chosenPlanCost.total()}&nbsp;&nbsp;
                <span className={chosenPlanCost.original !== parseFloat(chosenPlanCost.total()) ? "old" : "hidden"}>${chosenPlanCost.original}</span>
              </span>
              <select
                name="numberOfYears"
                id="numberOfYears"
                value={numYears}
                style={{
                  border: "2px solid black",
                  borderRadius: "6px",
                  height: "min-content",
                  padding: "4px",
                }}
                onChange={(e) => setNumYears(e.target.value)}
              >
                <option value="1">1 Year</option>
                <option value="2">2 Years</option>
                <option value="3">3 Years</option>
              </select>
            </div>
            <div className="payment_overlay_summary_details">
              <span className="plan_terms">By subscribing, you agree to Arludo's recurring billing terms and conditions</span>
            </div>
            <div className={`payment_overlay_summary_coupon ${formData.couponDetails ? (formData.error ? "error" : "success") : ""}`}>
              <div className="input-group">
                <label htmlFor="cardName">Coupon Code</label>
                <input type="text" id="cardName" name="name" placeholder="Coupon Code" value={formData.coupon} onChange={(e) => setFormData({ ...formData, coupon: e.target.value })} required />
              </div>
              <span className={`success-text`}>Coupon Code Successfully Applied</span>
              <span className={`error-text`}>Error: Coupon Not Valid</span>
              <button
                type="submit"
                className="submit-button"
                onClick={e => { checkCouponValidity(); e.preventDefault() }}
              >
                {!loading ? 'Apply Code' : <CircularProgress size={20} color='inherit' />}
              </button>
            </div>
            <div className="payment_overlay_information">
              <span>Want to use your NSW Creative Kids Voucher? Get in touch with us
                at <a href="mailto:info@arludo.com">info@arludo.com</a> and
                send us the details and we will manually process your rebate.</span>
            </div>
          </div>
        </div>
        <div className="payment_overlay_checkout">
          <div className="subscription_payment">
            <form className="payment-form" onSubmit={handleOnSubmitClick}>
              <div className="input-group" style={{ display: stripeTestMode ? "block" : "none" }}>
                <label htmlFor="alert_test_mode">WARNING: TEST MODE ACTIVE</label>
              </div>
              <div className="input-group">
                <label htmlFor="email">Billing Email</label>
                <input type="email" id="email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} required />
              </div>

              {/* <div className="input-group">
            <label>Payment method</label>
            <div className="payment-method-options">
              <button type="button" className="payment-method-button">Card</button>
              <button type="button" className="payment-method-button">AU Direct Debit</button>
            </div>
          </div> */}

              <div className="input-group">
                <label htmlFor="cardName">Cardholder name</label>
                <input type="text" id="cardName" name="name" placeholder="Full name on card" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} required />
              </div>
              <div className="card-details">
                {
                  chosenPlanCost.total() === "0.00" ? (
                    <div className="input-group">
                      <label htmlFor="cardNumber">Card information</label>
                      <div className="card-number" style={{
                        padding: "4px",
                      }}>Cost Free</div>
                    </div>
                  ) : (
                    <div className="input-group">
                      <div className="input-group">
                        <label htmlFor="cardNumber">Card information</label>
                        <CardNumberElement
                          id="cardNumber"
                          type="text"
                          name="cardNumber"
                          required />
                      </div>
                      <div className="input-group exp-cvc">
                        <CardExpiryElement
                          type="text"
                          id="exp"
                          name="exp"
                          className="expiry"
                          required
                        />
                        <CardCvcElement
                          type="text"
                          id="cvc"
                          name="cvc"
                          required
                        />
                      </div>
                    </div>
                  )
                }



                <div className="input-group">
                  <label htmlFor="zip">Post Code</label>
                  <input type="text" id="zip" name="zip" placeholder="Post Code / ZIP"
                    value={formData.zip}
                    onChange={(e) => setFormData({ ...formData, zip: e.target.value })} required />
                </div>
              </div>

              <span className={`error-message ${formData.error && "active"}`}>{formData.error}</span>

              <button
                type="submit"
                className="submit-button"
                onClick={handleOnSubmitClick}
              >{!loading ? 'Subscribe' : <CircularProgress size={20} color="inherit" />}</button>
            </form>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Subscription;