import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';

const FaqItem = ({ title, content }) => {
    const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div style={{ backgroundColor: theme.palette.primary.main, padding: '10px', margin: '10px 0', borderRadius: '10px' }}>
      <h2 style={{ cursor: 'pointer', color: 'white', marginLeft: '20px', marginRight: '20px', fontWeight: 'bold' }} onClick={toggleOpen}>{title}</h2>
      <div style={{ maxHeight: isOpen ? '100vh' : '0', overflow: 'hidden', transition: 'max-height 1s ease-in-out' }}>
        {isOpen && content.split('\n').map((line, i) => (
            <React.Fragment key={i}>
            <p style={{ cursor: 'pointer', color: 'black', marginLeft: '20px', marginRight: '20px' }}>{line}</p>
            </React.Fragment>
        ))}
        </div>
    </div>
  );
};

const FaqPage = () => {
    
  const faqData = [
    {
      title: 'How can you use video games to teach science?',
      content: 'Because science games are fun! The science games that we created are all experiments – so each time students play an Arludo game, they are running an experiment and collecting data. The beauty of this approach is that students don’t know they are acting like scientists! But as they play, they are exploring a new world and learning the science concepts in the game. What’s really special is that when they start exploring the data, it quickly makes sense because of all their science experiences while playing the game.\nUsing videogames to teach science also works because it lowers the costs of making mistakes. Students have fun playing games and exploring the worlds that we created so they are willing to just jump in and start exploring. Even if they make a mistake, students jump right back in because the costs of making that mistake are almost non-existent. It’s discovery learning through play!'
    },
    {
      title: 'Which science topics can I teach with Arludo?',
      content: 'Currently, we have science games about biology, psychology, maths, and ethics. Soon we’ll be developing more games for physics and chemistry as well. So sign up to make sure you’re updated on all our new science games and science programs coming out! All the science games that we use are created by Arludo. That means we know everything about the games and can ensure that students get the most out of using our games.',
    },
    {
        title: 'How can you use data to teach science?',
        content: 'Science is all about exploring data to make conclusions – so we always wondered how students are learning science without exploring data! Most science programs focus on wrote learning and memorisation of facts. Although understanding certain concepts is important, facts are easy to collect using the internet – those aren’t the skills we should be teaching kids anymore!\nWhat kids need to learn is how to make conclusions from the information they gather. And practicing those skills is more difficult because it requires that students have a lot of data to be able to explore and make conclusions.  Also, it requires that they do this over and over until they get the hang of it. This means you would be collecting an enormous amount of data on your own – that’s not feasible!\nThe Ingenious program simplifies all the hard stuff so that you can focus on your child’s growth. Isn’t that why you decided to homeschool?'
    },
    {
        title: 'Would this be suitable for a science enrichment program?',
        content: 'Absolutely. Because our ecosystem of games and digital worksheets are extremely flexible, it means that student learning in science can easily be extended. That means that you can teach science extension for a group of students, or can easily create programs for gifted students.\nMany of our games also allow students to create their own experiment. That means that online science learning can be extended in numerous ways.'
    },
    {
        title: 'Does your program match the science curriculum?',
        content: 'Absolutely. Teachers have told us that the hardest part of teaching the science curriculum are not the topics, but teaching science skills. Arludo’s teacher portal was built to teach working scientifically skills – critical thinking, experimental design, data collection, data interpretation and analytical thinking, and making conclusions. These are the most difficult skills to teach and skills that students will use throughout their lives.\nArludo is the only company that can do this because we focus on helping students understand data by helping them practice exploring data in a comfortable environment.'
    },
    {
        title: 'Is this suitable for a homeschool science program?',
        content: 'Absolutely. A homeschool science program requires that students at home are able to run science experiments at home to explore science questions with others no matter where they are. Students should be able to work with others to collect data so that they can visualise and explore those data to make conclusions. This is exactly how the Arludo platform was built – to allow students to anonymously work together to collect data to make scientific conclusions and to learn to think scientifically.\nIt doesn’t matter if you are learning science at home, playing science games in class, or using the science games to teach a class online, students each get a consistent experience which provides every student an opportunity to excel.'
    },
    {
        title: 'What science equipment will I need?',
        content: 'You won’t need any special equipment – we’re not making baking soda volcanoes here! All you’ll need is what you normally use to interact with the internet: a computer, chromebook, tablet, or mobile phone. These are devices every school has access to, and it doesn’t matter how old the devices are as long as they can connect to the internet.\nIf you’d like help setting up your devices with Arludo, just get in touch with us at info@arludo.com.'
    },
    {
        title: 'Do you collect student personal data?',
        content: 'Absolutely not. Each one of our users is completely anonymous to us and we can’t identify them. That means that their provacy is 100% guaranteed.\nWe think this is really important as real learning requires students to make mistakes, and making mistakes can make students feel vulnerable. That’s why we’ve created a comfortable environment to allow students to learn and not have to work about being identified. That’s the way it should be online.'
    },
    {
        title: 'What age group is Arludo for?',
        content: 'Every age! We know that sounds silly but our Founder Michael KAsumovic is an Associate Professor at UNSW Sydney and he has used Arludo to teach his students, and also with students as young as year three when he visits primary schools. Arludo games are meant for every age and we ensure our worksheets are also available for students of all ages by moderating the difficulty of the worksheets, the graphs that students explore, and the vocabulary we use.'
    },
    {
      title: 'Can I complete worksheets more than once?',
      content: 'Yes you can. But each time you complete a worksheet, the new answers are written over the previous one.'
    },
    {
      title: 'I would like to have our worksheets marked.',
      content: 'And we would love to mark them! Just upgrade to Platinum and we’ll notify our scientists and mark your worksheet once it’s completed.'
    }
  ];

  return (
    <div>
      {faqData.map((faq, index) => (
        <FaqItem key={index} title={faq.title} content={faq.content} />
      ))}
    </div>
  );
};

export default FaqPage;
