const tutorDefaultState = {
  tutor: {},
  isLoaded: false,
};

const tutorReducer = (state = tutorDefaultState, action) => {
  switch (action.type) {
    case "SET_TUTOR":
      return {
        ...state,
        tutor: action.tutor,
        isLoaded: true,
      };

    case "SET_NO_TUTOR":
      return {
        ...state,
        tutor: {},
        isLoaded: true,
      };

    case "UPDATE_TUTOR":
      return {
        ...state,
        tutor: {
          ...state.tutor,
          name: action.tutorName,
          schoolYear: action.schoolYear,
        },
      };

    case "ADD_STUDENT":
      return {
        ...state,
        tutor: {
          ...state.tutor,
          ...state.tutor.students.push({
            studentName: action.studentName,
            studentId: action.studentId,
          }),
        },
      };

    case "ADD_WORKSHEET":
      return {
        ...state,
        tutor: {
          ...state.tutor,
          ...state.tutor.worksheets.push(action.worksheet),
          ...action.paymentMethod,
        },
      };

    case "ADD_PACKAGE":
      return {
        ...state,
        tutor: {
          ...state.tutor,
          worksheets: [...state.tutor.worksheets, ...action.worksheets],
          ...action.paymentMethod,
        },
      };

    case "FINISH_WALKTHROUGH":
      return {
        ...state,
        tutor: {
          ...state.tutor,
          walkthrough: true,
        }
      };

    case "CLEAR_TUTOR":
      return tutorDefaultState;

    default:
      return state;
  }
};

export default tutorReducer;
