import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container
} from '@material-ui/core';
import Copyrights from '../../components/Copyrights';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';

import { startSetLogin, startResendConfirmEmailCode } from '../../actions/auth';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: "url('/img/login-page.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  logo: {
    maxHeight: 80,
    marginTop: "30%",
    marginBottom: 60
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "#fff"
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!email) {
        toast.error("Please provide the email address");
      } else if (!password) {
        toast.error("Please provide the password");
      } else {
        await dispatch(startSetLogin(email, password));
      }
    } catch (err) {
      if (err.message === "User is not confirmed.") {
        await startResendConfirmEmailCode(email);
        history.push("/confirmemail", { email, password });
      } else {
        toast.error(err.message);
      };
    };
  };

  return (
    <Box className={classes.background}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            src="/img/arludo_logo_horizontal_notagline.jpg"
            alt="Arludo Logo"
            className={classes.logo}
          />
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant="h5">
            Login
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Login
            </Button>
            <Grid container>
              <Grid item xs>
                <Typography
                  variant="h5"
                  color="textSecondary"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  onClick={() => history.push("/resetpassword")}
                >
                  Forgot password?
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  color="textSecondary"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  onClick={() => history.push("/register")}
                >
                  Don't have an account? Sign Up
                </Typography>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyrights />
        </Box>
      </Container>
    </Box>
  );
};

export default LoginPage;