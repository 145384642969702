import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import { useDispatch, useSelector } from "react-redux";

// Pages Import
import LoginPage from "../pages/auth/LoginPage";
import RegisterPage from "../pages/auth/RegisterPage";
import ConfirmEmailPage from "../pages/auth/ConfirmEmailPage";
import ResetPasswordPage from "../pages/auth/ResetPasswordPage";
import ResetPasswordConfirmPage from "../pages/auth/ResetPasswordConfirmPage";
import TutorDetailsPage from "../pages/auth/TutorDetailsPage";
import ViewWorksheetPage from "../pages/main/worksheetsPages/ViewWorksheetPage";

// Routes Import
import PrivateRoute from "./PrivateRoute";
import AuthRoute from "./AuthRoute";
import HasDataRoute from "./HasDataRoute";

// Components Import
import Loading from "../components/Loading";

// Actions Import
import { startSetAuth } from "../actions/auth";
import { startSetTutorData } from "../actions/tutor";

import { QueryClientProvider, queryClient } from '../actions/queryClient';
import FacebookPixel from '../components/FacebookPixel';

const AppRouter = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const tutorDataLoaded = useSelector((state) => state.tutor.isLoaded);

  useEffect(() => {
    dispatch(startSetAuth());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated !== null) {
      setLoading(true);
      if (isAuthenticated) {
        dispatch(startSetTutorData());
      } else {
        setLoading(false);
      }
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    if (tutorDataLoaded) {
      setLoading(false);
    }
  }, [tutorDataLoaded]);

  return loading ? (
    <Loading width="full" />
  ) : (
    <QueryClientProvider client={queryClient}>
      <FacebookPixel />
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <AuthRoute path="/login" component={LoginPage} />
            <AuthRoute path="/register" component={RegisterPage} />
            <AuthRoute path="/confirmemail" component={ConfirmEmailPage} />
            <AuthRoute path="/resetpassword" component={ResetPasswordPage} />
            <AuthRoute
              path="/resetpasswordconfirm"
              component={ResetPasswordConfirmPage}
            />
            <HasDataRoute path="/tutordetails" component={TutorDetailsPage} />
            <Route
              path="/worksheet/:worksheetId&:tutorId&:studentId"
              component={ViewWorksheetPage}
            />
            <PrivateRoute />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default AppRouter;
