import React from 'react';
import { Helmet } from 'react-helmet';

const VoiceflowChat = () => (
  <Helmet>
    <script type="text/javascript">{`
      (function(d, t) {
          var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
          v.onload = function() {
            window.voiceflow.chat.load({
              verify: { projectID: '656971f248ed8b000771551b' },
              url: 'https://general-runtime.voiceflow.com',
              versionID: 'production'
            });
          }
          v.src = "https://cdn.voiceflow.com/widget/bundle.mjs"; v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
      })(document, 'script');
    `}</script>
  </Helmet>
);

export default VoiceflowChat;