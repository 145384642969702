import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import _ from "lodash";
import {
  Container,
  Button,
  CssBaseline,
  Grid,
  Typography,
  Box,
  Collapse,
  Hidden,
  Tooltip
} from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LockIcon from "@material-ui/icons/Lock";
import Loading from "../../components/Loading";
import { checkSubscriptionType } from "../../actions/tutor";


const useStyles = makeStyles((theme) => ({
  title: {
    borderBottom: "1px solid #BDBDBD",
    margin: "30px 0",
  },
  subTitle: {
    margin: "30px 0",
    textAlign: "center",
    fontSize: 18,
    fontWeight: "bold",
  },
  item: {
    backgroundColor: "#fff",
    boxShadow: "0px 2px 4px #dadada",
    padding: "40px 30px",
    borderRadius: 5,
    marginBottom: 20,
    minWidth: 390,
  },
  button: {
    fontSize: 12,
    color: "#fff",
    textTransform: "capitalize",
    padding: '6px 6px'
  },
  buttonBox: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    width: "100%",
    height: "100%",
  },
  modalBody: {
    width: "40%",
    height: "30%",
    padding: 50,
    boxShadow: "0px 2px 4px #dadada",
    backgroundColor: "#fff",
    borderRadius: 5,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    color: theme.palette.primary.main,
  },
  menuIcon: {
    alignSelf: "center",
    margin: "0 10px",
    textAlign: "center",
  },
}));


const WorksheetsPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { email, id, worksheets } = useSelector((state) => state.tutor.tutor);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { subscriptionType } = useSelector((state) => state.tutor.tutor);
  const isSummaryAvailable = checkSubscriptionType( subscriptionType)?.type === "platinum";
  const isPro =  checkSubscriptionType( subscriptionType)?.type === "pro" || checkSubscriptionType( subscriptionType)?.type === "platinum";

  useEffect(() => {
    const tutorData = _(worksheets).groupBy(x => x.studentId).map((value, key) => ({ studentName: value[0].studentName, studentId: key, worksheets: value })).value();

    const filteredData = tutorData.map((item, i) => {
      const worksheets = item.worksheets.filter((worksheet) => !worksheet.packageId);
      const packagesData = item.worksheets.filter((worksheet) => worksheet.packageId);
      const packages = _(packagesData).groupBy((x) => x.packageId).map((value, key) => ({ packageId: key, worksheets: value })).value();
      
      return {
        studentName: item.studentName,
        studentId: item.studentId,
        packages,
        worksheets,
        isOpen: true
      }
    });

    console.log(filteredData);

    setData(filteredData);
    setLoading(false);
  }, [worksheets]);

  const handleItemCollapse = (index) => {
    let updatedList = data.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          isOpen: !item.isOpen
        }
      }
      return item
    })
    setData(updatedList);
  };

  const handleViewWorksheet = (worksheet, studentId) => {
    const win = window.open(`/worksheet/${worksheet.id}&${id}&${studentId}`);
    win.focus();
    
  };

  const handleViewQuiz = (worksheet, studentName) => {
    history.push(`/quiz/${worksheet.quizId}&${worksheet.id}&${id}&${studentName}`);
  };
  
  return (
    loading ?
      <Loading />
      :
      <Container component="main" maxWidth="lg" style={{ padding: '0 0px' }}>
        <CssBaseline />
        <Typography
          component="h5"
          variant="h5"
          color="textSecondary"
          className={classes.title}
        >
          My Worksheets
        </Typography>
        {data.map((item, i) => (
          <Box className={classes.item} key={i}>
            <Grid 
              container
              spacing={1}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item xs={10} sm={5}>
                <Typography>
                  {item.studentName}'s Worksheets
                </Typography>
              </Grid>
              <Hidden mdDown>
                <Grid item xs={12} sm={1}>
                  <Typography>
                    Completed
                  </Typography>
                </Grid>
              </Hidden>
              <Grid item xs={2} sm={6} style={{ textAlign: "end" }}>
                {item.isOpen ?
                  <KeyboardArrowUpIcon
                    onClick={() => handleItemCollapse(i)}
                    className={classes.icon}
                  />
                  :
                  <KeyboardArrowDownIcon
                    onClick={() => handleItemCollapse(i)}
                    className={classes.icon}
                  />
                }
              </Grid>
            </Grid>
            <Collapse in={item.isOpen}>
              {item.worksheets.map((worksheet, i) => (
                <Grid container 
                  spacing={1} 
                  style={{ marginTop: 10, display: "flex", justifyContent: "space-between" }} 
                  key={i}
                >
                  <Grid item xs={12} sm={5} style={{ display: "flex", alignSelf: "center" }}>
                    <img style={{ borderRadius: "5px", marginRight: 10 }} height='40px' src={worksheet.worksheetCoverImage} alt="Cover" />
                    <Typography
                      color="textSecondary"
                      style={{ alignSelf: "center" }}
                    >
                      {worksheet.worksheetName}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          color="secondary"
                          style={{
                            alignSelf: "center",
                            backgroundColor: "#f5f5f5",
                            borderRadius: "0.375em",
                            fontSize: ".75rem",
                            justifyContent: " center",
                            paddingLeft: "0.75em",
                            paddingRight: "0.75em",
                            whiteSpace: "nowrap",
                            width: "min-content",
                          }}
                        >
                          {item.studentName}
                        </Typography>
                        <Typography
                          color="primary"
                          style={{
                            alignSelf: "center",
                            backgroundColor: "#f5f5f5",
                            borderRadius: "0.375em",
                            fontSize: ".75rem",
                            justifyContent: " center",
                            paddingLeft: "0.75em",
                            paddingRight: "0.75em",
                            whiteSpace: "nowrap",
                            width: "min-content",
                          }}
                        >
                          {worksheet.ageGroup && (worksheet.ageGroup === "university" ? worksheet.ageGroup : "Years " + worksheet.ageGroup)}
                        </Typography>
                        <Hidden lgUp>
                          <Typography
                            color="secondary"
                            style={{
                              alignSelf: "center",
                              backgroundColor: "#f5f5f5",
                              borderRadius: "0.375em",
                              fontSize: ".75rem",
                              justifyContent: " center",
                              paddingLeft: "0.75em",
                              paddingRight: "0.75em",
                              whiteSpace: "nowrap",
                              width: "min-content",
                              marginLeft: '5px' // Add some space between the two components
                            }}
                          >
                            {"completed: "}
                            {worksheet.completedAt ? worksheet.completedAt : "TBD"}
                          </Typography>
                        </Hidden>
                        
                      </div>
                      
                    </Typography>
                  </Grid>
                  
                  <Hidden mdDown>
                    <Grid item xs={12} sm={1} style={{ display: "flex", alignSelf: "center" }}>
                      
                        <Typography
                          color="textSecondary"
                          style={{ fontSize: '15px' }} 
                        >
                          {worksheet.completedAt ? worksheet.completedAt : "TBD"}
                        </Typography>
                    </Grid>
                  </Hidden>

                  <Grid item xs={12} md={6}>
                    <Box className={classes.buttonBox}>
                      {(!worksheet.lessonPlan || worksheet.lessonPlan === "") ?
                        void 0 :
                        <Tooltip title={ ( (isPro || worksheet.free) ) ? "Open PDF" : "Renew your subscription to access"}>
                          <span>
                            <Button
                              disabled={ (!isPro && !worksheet.free) }
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              style={{ marginRight: 5 }}
                              onClick={() => window.open(worksheet.lessonPlan, "_blank")}
                              endIcon={ isPro || worksheet.free ? void 0 : <LockIcon /> } 
                            >
                              Lesson Plan
                            </Button>
                          </span>
                        </Tooltip>
                      }
                      
                      <Tooltip title={!isPro && !worksheet.free ? "Renew your subscription to access" : "Availble now!"}>
                        <span>
                          <Button
                            disabled={ !isPro && !worksheet.free }
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            style={{ marginRight: 5 }}
                            onClick={() => handleViewWorksheet(worksheet, item.studentId)}
                            endIcon={ isPro || worksheet.free ? void 0 : <LockIcon /> } 
                          >
                            Start
                          </Button>
                        </span>
                      </Tooltip>
                      <Tooltip title={!isPro && !worksheet.free ? "Renew your subscription to access" :
                        !worksheet.quizId ? "Start the worksheet and submit to view your answers" : "Available now!"
                      }>
                        <span>
                          <Button
                            disabled={ (!isPro && !worksheet.free) || !worksheet.quizId}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            style={{ marginRight: 5 }}
                            onClick={() => handleViewQuiz(worksheet, item.studentName, worksheet.completedAt)}
                            endIcon={ isPro || worksheet.free ? void 0 : <LockIcon /> } 
                          >
                            View
                          </Button>
                        </span>
                      </Tooltip>
                      <Tooltip title={
                        isSummaryAvailable ? (
                          worksheet.summaryId ? (
                            "Marked sheet is available now"
                            ) : (
                            worksheet.completedAt ? (
                              "Marked sheet will be available soon"
                            ) : (
                              "Submit the sheet to get it marked"
                            )
                          )
                        ) : (
                          "Marking is only available for Platinum users"
                        )
                      } arrow>
                        <span>
                          <Button
                            disabled={!worksheet.summaryId || !isSummaryAvailable}
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => history.push(`/worksheets/${worksheet.summaryId}`, {
                              worksheetId: worksheet.id,
                              name: worksheet.worksheetName,
                              coverImage: worksheet.worksheetCoverImage,
                              studentName: item.studentName,
                              email: email
                            })}
                            endIcon={isSummaryAvailable ? void 0 : <LockIcon /> } 
                          >
                            Marked
                          </Button>
                        </span>
                      </Tooltip>
                    </Box>
                  </Grid>
                  
                </Grid>
              ))}
            </Collapse>
          </Box>
        ))}
        {data.length < 1 && <Typography color="textSecondary" className={classes.subTitle}>
          You can add new worksheets from the Worksheets tab on the left!
        </Typography>}
      </Container>
  );
};


export default WorksheetsPage;