import { API3, TUTOR_SERVICE_URL } from "./constant";
import axios from "axios";

export const getWorksheets = async () => {
  try {
    const res = await axios.get(`${TUTOR_SERVICE_URL}/store`);
    return res.data;
  } catch (e) {
    console.log("error", e);
  }
};

export const getIBWorksheets = async () => {
  try {
    const res = await axios.get(`${TUTOR_SERVICE_URL}/store?gameCode=IB`);
    return res.data.templates;
  } catch (e) {
    console.log("error", e);
  }
};

export const finishWalkthrough = () => async (dispatch) => {
  try {
    const res = await axios.post(`${TUTOR_SERVICE_URL}/walkthrough`, {
      walkthrough: true,
    });
    if (res.data) {
      dispatch({
        type: "FINISH_WALKTHROUGH",
      });
    }
    return res.data;
  } catch (e) {
    console.log("error", e);
    return e.response.data;
  }
};

export const addWorksheet = (worksheet, paymentMethod) => ({
  type: "ADD_WORKSHEET",
  worksheet,
  paymentMethod,
});

export const checkoutSheet = (
  id,
  studentName,
  studentId,
  name,
  coverImage,
  free,
  ageGroup
) => {
  return async (dispatch) => {
    const { data } = await axios.post(`${TUTOR_SERVICE_URL}/addSheet`, {
      id,
      studentName,
      studentId
    });

    if (data.message === "success") {
      dispatch(
        addWorksheet({
          studentName,
          studentId,
          id,
          worksheetName: name,
          worksheetCoverImage: coverImage,
          completedAt: null,
          summaryId: null,
          free: free,
          ageGroup
        })
      );
    }
    return data.message;
  }

}

export const getWorksheetContent = async (worksheetId, tutorId, studentId) => {
  try {
    const res = await axios.get(
      `${TUTOR_SERVICE_URL}/sheetcontent?worksheetId=${worksheetId}&tutorId=${tutorId}&studentId=${studentId}`,
      {
        transformRequest: (data, headers) => {
          delete headers.common["Authorization"];
          return data;
        },
      }
    );
    return res.data;
  } catch (e) {
    console.log("error", e);
  }
};

export const startSubmitQuiz = async (
  tutorId,
  worksheetId,
  studentId,
  data
) => {
  try {
    const completedAt = new Date().toLocaleDateString("en-AU");
    const res = await axios.post(`${API3}/submitquiz`, {
      tutorId,
      worksheetId,
      studentId,
      data,
      completedAt,
    });
    return res.data;
  } catch (e) {
    console.log("error", e);
  }
};

export const getSummary = async (summaryId, worksheetId) => {
  try {
    const res = await axios.get(
      `${TUTOR_SERVICE_URL}/summary?summaryId=${summaryId}&worksheetId=${worksheetId}`
    );
    return res.data;
  } catch (e) {
    console.log("error", e);
  }
};

export const getTemplate = async (id) => {
  try {
    const res = await axios.get(`${TUTOR_SERVICE_URL}/template?id=${id}`);
    return res.data;
  } catch (e) {
    console.log("error", e);
  }
};

export const getWorksheet = async (id) => {
  try {
    const res = await axios.get(`${TUTOR_SERVICE_URL}/sheet?sheetId=${id}`);
    return res.data;
  } catch (e) {
    console.log("error", e);
  }
}

export const getQuiz = async (quizId) => {
  try {
    const { data: resData } = await axios.get(
      `${TUTOR_SERVICE_URL}/quiz?&quizId=${quizId}`
    );

    return resData.data;
  } catch (e) {
    console.log("error", e);
  }
};
