import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container
} from '@material-ui/core';
import Copyrights from '../../components/Copyrights';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';

import { startSetForgotPassword } from '../../actions/auth';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: "url('/img/signup-page.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  logo: {
    maxHeight: 80,
    marginTop: "30%",
    marginBottom: 60
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  subTitle: {
    fontSize: "14px",
    textAlign: "center",
    marginTop: 10
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "#fff"
  },
}));

const ResetPasswordPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!email) {
        toast.error("Please provide the email address");
      } else {
        await startSetForgotPassword(email);
        history.push("/resetpasswordconfirm", { email });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Box className={classes.background}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            src="/img/arludo_logo_horizontal_notagline.jpg"
            alt="Arludo Logo"
            className={classes.logo}
          />
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant="h5">
            Reset Your Password
          </Typography>
          <Typography className={classes.subTitle}>
            Enter your email address and we'll send you a code to reset your password.
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Send Code
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyrights />
        </Box>
      </Container>
    </Box>
  );
};

export default ResetPasswordPage;