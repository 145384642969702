const isProduction = process.env.REACT_APP_STAGE === "prod";


export const plans = {
  pro: {
    monthly: {
      name: "PRO",
      cost: "14.99",
      pricingDetails: "one family",
      frequency: "monthly",
      color: "#ff3e5a",
      priceId: isProduction ? "price_1OCzt9A0KZhaOsQ82u91sAk9" : "price_1OD0GNA0KZhaOsQ8MoSIMZDe", 
      features: [
        "1 Parent Account",
        "30+ Experiments & Graphs",
        "Explore Data in Real Time",
        "Platform Support",
        "All Your Kids Play for Free"
      ],
      featuresPlus: [
        "20+ additional games",
        "Additional worksheets",
        "Real-time science data",
        "Platform support",
        "Find out more here",
        "Privacy access to new games",
        "Vote for new games",
        "Request science topics"
      ],
      couponCode: "",
    },
    annual: {
      name: "PRO",
      cost: "11.2425",
      pricingDetails: "one family",
      frequency: "annual",
      color: "#ff3e5a",
      priceId: isProduction ? "price_1OQ3phA0KZhaOsQ8EfLUXDkK" : "price_1OQ3muA0KZhaOsQ89GNxUq4J",
      features: [
        "1 Parent Account",
        "30+ Experiments & Graphs",
        "Explore Data in Real Time",
        "Chat with Scientists",
        "Parent Facebook Community",
        "Platform Support",
        "All Your Kids Play for Free"
      ],
      featuresPlus: [
        "20+ additional games",
        "All digital worksheets",
        "Real time science data",
        "Platform support",
        "Chat with our scientists on Discord",
        "Join our monthly virtual incursions",
        "Access to the Parent Facebook Community",
        "Priority access to new games",
        "Vote for new games",
        "Request science topics"
      ],
      couponCode: "ARLUDOPRO",
    }
  },
  platinum: {
    monthly: {
      name: "PLATINUM",
      cost: "39.99",
      pricingDetails: "one family",
      frequency: "monthly",
      color: "#75c7b9",
      priceId: isProduction ?  "price_1OD0HsA0KZhaOsQ8lQBa05qL" : "price_1OD0HKA0KZhaOsQ83s9OC1av",
      features: [
        "1 Parent Account",
        "30+ Experiments & Graphs",
        "Explore Data in Real Time",
        "Platform Support",
        "Marked by Our Scientists",
        "Parent Facebook Community",
        "All Your Kids Play for Free"
      ],
      featuresPlus: [
        "Our scientists mark and provide feedback to your child's report",
        "20+ additional games",
        "All digital worksheets",
        "Real time science data",
        "Platform support",
        "Find out more here",
        "Priority access to new games",
        "Vote for new games",
        "Request science topics"
      ],
      couponCode: "",
    },
    annual: {
      name: "PLATINUM",
      cost: "29.99",
      pricingDetails: "one family",
      frequency: "annual",
      color: "#75c7b9",
      priceId:  isProduction ? "price_1OQ3qIA0KZhaOsQ8Q2OxpeQX" : "price_1OQ3lKA0KZhaOsQ8PCmJN8A6",
      features: [
        "1 Parent Account",
        "30+ Experiments & Graphs",
        "Explore Data in Real Time",
        "Marked by Our Scientists",
        "Chat with Scientists",
        "Parent Facebook Community",
        "Platform Support",
        "All Your Kids Play for Free"
      ],
      featuresPlus: [
        "Our scientists mark and provide feedback to your child’s report",
        "All digital worksheets",
        "Real time science data",
        "Platform support",
        "Chat with our scientists on Discord",
        "Join our monthly virtual incursions",
        "Access to the Parent Facebook Community",
        "Priority access to new games",
        "Vote for new games",
        "Request science topics"
      ],
      couponCode: "ARLUDOPLAT",
    }
  }
}

export default plans;