import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Box
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    height: "60%",
  },
  icon: {
    color: theme.palette.primary.main
  },
}));

const Loading = ({ width }) => {
  const classes = useStyles();

  return (
    <Box className={classes.body} style={{ width: width === "full" ? "100%" : "80%" }}>
      <CircularProgress size={80} className={classes.icon} />
    </Box>
  )
};

export default Loading;