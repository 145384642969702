import { Auth } from "aws-amplify";

export const setUser = (email) => ({
  type: "SET_USER",
  email,
});

export const setNoUser = () => ({
  type: "SET_NO_USER",
});

export const startSetAuth = () => {
  return async (dispatch) => {
    try {
      const res = await Auth.currentAuthenticatedUser();
      if (res.username) {
        dispatch(setUser(res.username));
      }
    } catch (e) {
      dispatch(setNoUser());
    }
  };
};

export const setLogin = (email) => ({
  type: "SET_LOGIN",
  email,
});

export const startSetLogin = (email, password) => {
  const username = email.trim().toLowerCase();
  return async (dispatch) => {
    const res = await Auth.signIn(username, password);
    if (res.username) {
      dispatch(setLogin(res.username));
      return res.username;
    }
  };
};

export const setLogout = () => ({
  type: "SET_LOGOUT",
});

export const startSetLogout = () => {
  return async (dispatch) => {
    await Auth.signOut();
    dispatch(setLogout());
  };
};

export const startSetRegister = async (email, password) => {
  const username = email.trim().toLowerCase();
  return await Auth.signUp({
    username,
    password: password,
    attributes: {
      email: username,
    },
  });
};

export const startConfirmEmail = (email, password, code) => {
  const username = email.trim().toLowerCase();
  return async (dispatch) => {
    await Auth.confirmSignUp(username, code);
    const res = await Auth.signIn(username, password);
    if (res.username) {
      dispatch(setLogin(res.username));
    }
  };
};

export const startResendConfirmEmailCode = async (email) => {
  const username = email.trim().toLowerCase();
  return await Auth.resendSignUp(username);
};

export const startSetForgotPassword = async (email) => {
  const username = email.trim().toLowerCase();
  return await Auth.forgotPassword(username);
};

export const startConfirmPasswordReset = async (email, code, password) => {
  const username = email.trim().toLowerCase();
  return await Auth.forgotPasswordSubmit(username, code, password);
};

export const startChangePassword = async (currentPassword, newPassword) => {
  const user = await Auth.currentAuthenticatedUser();
  return await Auth.changePassword(user, currentPassword, newPassword);
};
