import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import { Link } from "react-router-dom";

import {
  Container,
  Button,
  CssBaseline,
  Grid,
  Typography,
  CardMedia,
  Box,
  FormControl,
  Select
} from "@material-ui/core";
import Loading from "../../../components/Loading";
import Modal from "../../../components/Modal";
import {
  checkoutSheet,
  getWorksheet
} from "../../../actions/worksheet";
import PersonIcon from '@material-ui/icons/Person';
import { checkSubscriptionType, getTutor } from "../../../actions/tutor";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    borderBottom: "1px solid #BDBDBD",
    margin: "30px 0",
  },
  category: {
    padding: "5px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    display: "inline-block",
    borderRadius: "5px",
  },
  selectRoot: {
    "&:focus": {
      backgroundColor: "inherit",
    },
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    width: 300,
    display: "block",
    marginBottom: 100,
    color: "white",
  },
  checkbox: {
    display: "block",
    color: "#737373",
  },
}));

const StoreCheckoutPage = (props) => {
  const { id, type } = props.match.params;
  const tutorData = useSelector((state) => state.tutor.tutor);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [data, setData] = useState({});
  const [packageWorksheets, setPackageWorksheets] = useState([]);
  const [assignedStudentId, setAssignedStudentId] = useState("");
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [isPremium, setIsPremium] = useState(false);

  useEffect(() => {
    (async () => {
      const tutor = await getTutor();
      setIsPremium(checkSubscriptionType(tutor.tutor.subscriptionType));
      if (type === "worksheet") {
        const worksheetData = await getWorksheet(id);
        if (worksheetData) {
          setData(worksheetData.sheet);
          console.log(worksheetData.sheet);
          setLoading(false);
        }
        
      } else if (type === "package") {
        // packages are outdated 
        console.error("Should not be using packages");
      }
    })();
  }, [type, id]);

  const handleAdd = async () => {
    setOpen(true);
    const studentName = tutorData.students.find(
      (item) => item.studentId === assignedStudentId
    ).studentName;
    const results = await dispatch(
      checkoutSheet(
        id,
        studentName,
        assignedStudentId,
        data.name,
        data.coverImage,
        data.price === 0,
        data.ageGroup,
        data.lessonPlan
      )
    );
    if (results === "success") {
      history.push("/worksheets");
    } else {
      toast.error(results);
      setOpen(false);
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <Typography
        component="h1"
        variant="h5"
        color="textSecondary"
        className={classes.title}
      >
        Order Information
      </Typography>
      <Grid container spacing={10}>
        <Grid item xs={12} sm={12} className={classes.grid}>
          <CardMedia
            component="img"
            alt="Cover"
            image={data.coverImage}
            style={{
              width: 140,
              height: "inherit",
              alignSelf: "flex-start",
              borderRadius: "20%",
              marginRight: "20px",
            }}
          />
          <Box>
            <Typography gutterBottom variant="h5">
              {data.name}
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
              color="textSecondary"
              component="p"
              style={{ margin: "20px 0", whiteSpace: "pre-wrap" }}
            >
              {data.description}
            </Typography>
            <Box style={{ marginBottom: "20px" }}>
              <Typography gutterBottom variant="h6">
                Category:
              </Typography>
              <Typography gutterBottom variant="body1" color="textSecondary">
                {data.category}
              </Typography>
            </Box>
            {/* <Box style={{ marginBottom: "20px" }}>
              <Typography gutterBottom variant="h6">
                Price:
              </Typography>
              <Typography
                gutterBottom
                variant="body1"
                color="textSecondary"
                component="p"
              >
                {data.price === 0 ? "$0.00" : `$${data.price}`}
              </Typography>
            </Box> */}
            <Box style={{ marginBottom: "20px" }}>
              <Typography gutterBottom variant="h6">
                Age Group:
              </Typography>
              <Typography
                gutterBottom
                variant="body1"
                color="textSecondary"
                component="p"
              >
                {data.ageGroup} Years
              </Typography>
            </Box>
            <Box style={{ marginBottom: "20px" }}>
              <Typography gutterBottom variant="h6">
                Worksheet Type:
              </Typography>
              <Typography
                gutterBottom
                variant="body1"
                color="textSecondary"
                component="p"
              >
                {type === "worksheet" ? "Single" : "Package"}
              </Typography>
            </Box>
            <Box style={{ marginBottom: "20px" }}>
              <Typography gutterBottom variant="h6">
                Assign Student:
              </Typography>
              {tutorData.students.length > 0 ? (
                <Box>
                  <FormControl variant="outlined">
                    <Select
                      native
                      value={assignedStudentId}
                      onChange={(e) => setAssignedStudentId(e.target.value)}
                      classes={{ root: classes.selectRoot }}
                      style={{ width: 260 }}
                      inputProps={{
                        name: "age",
                        id: "outlined-age-native-simple",
                      }}
                    >
                      <option aria-label="None" value="" />
                      {tutorData.students.map((student, i) => (
                        <option key={i} value={`${student.studentId}`}>
                          {student.studentName}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography
                    gutterBottom
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    New students can be added from the profile tab on the left
                  </Typography>

                  {data.price === 0 || isPremium ? (
                    <Button
                      disabled={
                        tutorData.students.length > 0 && assignedStudentId
                          ? false
                          : true
                      }
                      onClick={() => handleAdd()}
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                    >
                      Add Worksheet
                    </Button>
                  ) : (
                    <Button
                      onClick={() => history.push(`/subscribe`)}
                      variant="contained"
                      color="secondary"
                      endIcon={ <LockIcon /> }
                      className={classes.button}
                    >
                      Subscribe
                    </Button>
                  )}
                </Box>
              ) : (
                <Box style={{ marginBottom: "20px" }}>
                  <Typography
                    gutterBottom
                    variant="body1"
                    color="textSecondary"
                    component="p"
                    style={{ marginBottom: "20px" }}
                  >
                    No students available in your account to be assigned to this{" "}
                    {type === "worksheet" ? "worksheet" : "package"}
                  </Typography>

                  
                  <Link to="/profile">
                    <Button variant="contained" color="grey" startIcon={<PersonIcon />}>
                      Add a new student
                    </Button>
                  </Link>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Modal open={open} />
    </Container>
  );
};

export default StoreCheckoutPage;
