import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Box, TextField } from "@material-ui/core";

import Modal from "./Modal";
import { startChangePassword } from "../actions/auth";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(3, 0, 2),
    width: 200,
    color: "white",
  },
  title: {
    width: 250,
    fontWeight: "bold",
    alignSelf: "center",
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const tutorData = useSelector((state) => state.tutor.tutor);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePassword, setChangePassword] = useState(false);
  const [open, setOpen] = useState(false);

  const handleChangePassword = () => {
    setChangePassword(true);
  };

  const handleSaveChanges = async () => {
    try {
      if (!currentPassword) {
        toast.error("Please provide the current password");
      } else if (!newPassword) {
        toast.error("Please provide the new password");
      } else if (!confirmPassword) {
        toast.error("Please confirm the password");
      } else {
        if (newPassword !== confirmPassword) {
          toast.error("The new password does not match the confirmed password");
        } else {
          setOpen(true);
          await startChangePassword(currentPassword, newPassword);
          toast.success("The password has been changed successfully");
          setChangePassword(false);
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setOpen(false);
        }
      }
    } catch (e) {
      toast.error(e.message);
      setOpen(false);
    }
  };

  return (
    <Box style={{ width: "max-content" }}>
      <Box style={{ display: "flex" }}>
        <Typography
          gutterBottom
          variant="h6"
          color="textSecondary"
          style={{ width: 250, fontWeight: "bold" }}
        >
          Email:
        </Typography>
        <Typography gutterBottom variant="h6" color="textSecondary">
          {tutorData.email}
        </Typography>
      </Box>
      {!changePassword ? (
        <Box>
          <Box style={{ display: "flex" }}>
            <Typography
              gutterBottom
              variant="h6"
              color="textSecondary"
              style={{ width: 250, fontWeight: "bold" }}
            >
              Password:
            </Typography>
            <Typography gutterBottom variant="h6" color="textSecondary">
              ********
            </Typography>
          </Box>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => handleChangePassword()}
            >
              Change Password
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box style={{ display: "flex" }}>
            <Typography
              gutterBottom
              variant="h6"
              color="textSecondary"
              className={classes.title}
            >
              Current Password:
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              name="password"
              type="password"
              autoComplete="current-password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </Box>
          <Box style={{ display: "flex" }}>
            <Typography
              gutterBottom
              variant="h6"
              color="textSecondary"
              className={classes.title}
            >
              New Password:
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              name="password"
              type="password"
              autoComplete="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Box>
          <Box style={{ display: "flex" }}>
            <Typography
              gutterBottom
              variant="h6"
              color="textSecondary"
              className={classes.title}
            >
              Confirm Password:
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              name="password"
              type="password"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Box>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => handleSaveChanges()}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      )}
      <Modal open={open} />
    </Box>
  );
};

export default ChangePassword;
