import React from "react";
import { Container, CssBaseline } from "@material-ui/core";
import Loading from "../../components/Loading";

import { getWorksheets } from "../../actions/worksheet";
import Cards from "../../components/Cards";
import { getTutor } from "../../actions/tutor";

import { useQuery } from 'react-query';

const StorePage = () => {
  const { data: data1, status: status1, refetch: refetch1 } = useQuery('worksheets', getWorksheets, {
    enabled: false, // Disable auto-refetch
  });

  const { data: data2, status: status2, refetch: refetch2 } = useQuery('tutor', getTutor, {
    enabled: false, // Disable auto-refetch
  });

  if (status1 === 'success' && status2 === 'success') {
    return (
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Cards templates={data1.templates.filter((worksheet) => worksheet.category !== "GDC")} tutor={data2} type="worksheet" title="Worksheets" />
      </Container>
    )
  } else {
    refetch1();
    refetch2();
    return (
      <Loading />
    )
  }
};

export default StorePage;
