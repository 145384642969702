import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Box } from "@material-ui/core";
import AddBox from '@material-ui/icons/AddBox';


function capitalise(word) {
  return (typeof word === 'string' && word.length > 0) ? 
    word.trim().charAt(0).toUpperCase() + word.slice(1) : word;
}

const useStyles = makeStyles((theme) => ({

  button: {
    margin: theme.spacing(3, 0, 2),
    width: 200,
    color: "white",
  },
  title: {
    width: 250,
    fontWeight: "bold",
    alignSelf: "center",
  },
}));

const ChangeSubscription = () => {
  const history = useHistory();
  const classes = useStyles();
  const tutorData = useSelector((state) => state.tutor.tutor);

  return (
    <Box style={{ width: "max-content" }}>
      <Box style={{ display: "flex" }}>
        <Typography
          gutterBottom
          variant="h6"
          color="textSecondary"
          style={{ width: 250, fontWeight: "bold" }}
        >
          Type:
        </Typography>
        <Typography gutterBottom variant="h6" color="textSecondary">
          {capitalise(tutorData.subscriptionType)}
        </Typography>
      </Box>
      {tutorData.subscriptionType === 'free' || tutorData.subscriptionType === 'pro' ?
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => history.push(`/subscribe`)}
            startIcon={<AddBox />}
          >
            Upgrade
          </Button>
        </Box> : null 
      }
      
    </Box>
  );
};

export default ChangeSubscription;
