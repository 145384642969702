import React from "react";
import ReactDOM from "react-dom";
import Amplify, { Auth } from "aws-amplify";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ToastContainer } from "react-toastify";
import axios from "axios";

import configureStore from "./store/configureStore";
import AppRouter from "./routers/AppRouter";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

const store = configureStore();

Amplify.configure({
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id: "ap-southeast-2_opmOVKuVt",
  aws_user_pools_web_client_id: "7l9j6gcvkc34iqp30do9gm2ctg",
});

// Inject access token in every request
axios.interceptors.request.use(async (config) => {
  try {
    const session = await Auth.currentSession();
    // User is logged in. Set auth header on all requests
    config.headers.Authorization = session.idToken.jwtToken;
    config.headers.AccessToken = session.accessToken.jwtToken;
    return await Promise.resolve(config);
  } catch {
    return await Promise.resolve(config);
  }
});

const stripePromise =
  process.env.REACT_APP_STAGE === "prod"
    ? loadStripe("pk_live_JoU1miCuZzU10LJucqlbIQQC") 
    : loadStripe("pk_test_yLqHbaD3Jlig9QHk6CYjSWNp")

const theme = createTheme({
  palette: {
    primary: {
      main: "#53CBBA",
    },
    secondary: {
      main: "#FF3E5A",
    },
  },
});

const jsx = (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Elements stripe={stripePromise}>
        <AppRouter />
        <ToastContainer />
      </Elements>
    </ThemeProvider>
  </Provider>
);

ReactDOM.render(jsx, document.getElementById("app"));
