// Importing Libraries
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Link, useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import getInitials from "../selectors/getInitials";
import {
  Drawer,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  AppBar,
  Toolbar,
  CssBaseline,
  IconButton,
  Divider,
  Hidden,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import HomeIcon from '@material-ui/icons/Home';
import StoreIcon from '@material-ui/icons/Store';
import ListAltIcon from '@material-ui/icons/ListAlt';
import GamesIcon from "@material-ui/icons/VideogameAsset";
import QuestionAnswerOutlined from "@material-ui/icons/QuestionAnswerOutlined.js";
// import EventNoteIcon from '@material-ui/icons/EventNote';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddBox from '@material-ui/icons/AddBox';

// Importing Pages
import HomePage from "../pages/main/HomePage";
import WorksheetsPage from '../pages/main/WorksheetsPage';
import SummaryPage from "../pages/main/worksheetsPages/SummaryPage";
import StorePage from '../pages/main/StorePage';
import GameDesignStorePage from '../pages/main/GameDesignStorePage';
import EventPage from '../pages/main/EventPage';
import ProfilePage from '../pages/main/ProfilePage';
import StoreWorksheetPage from "../pages/main/storePages/StoreWorksheetPage";
import StoreCheckoutPage from "../pages/main/storePages/StoreCheckoutPage";
import SubscriptionPage from "../pages/main/subscription";
import FaqPage from "../pages/main/FaqPage.js";
import ViewQuizPage from "../pages/main/worksheetsPages/ViewQuizPage";

import { startSetLogout } from "../actions/auth";
import { clearTutorData } from "../actions/tutor";

import VoiceflowChat from '../components/VoiceflowChat.js';

import { Button } from "@material-ui/core";
import Walkthrough from "../components/Walkthrough.jsx";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min.js";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: "#fff",
    boxShadow: "0px 1px #e0e0e0"
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  isActive: {
    borderRight: `4px solid ${theme.palette.primary.main}`
  },
  logoInitials: {
    display: "flex",
    backgroundColor: `${theme.palette.primary.main}`,
    color: "white",
    borderRadius: "50%",
    width: "120px",
    height: "120px",
    fontSize: "60px",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "initial"
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    width: 200,
    color: "white",
  }
}));


const MainRoute = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const tutorData = useSelector((state) => state.tutor.tutor);
  const [mobileOpen, setMobileOpen] = useState(false);

  const [isOverlayActive, setWalkthroughActive] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    await dispatch(startSetLogout());
    await dispatch(clearTutorData());
  };

  const itemsList = [
    {
      text: "Home",
      icon: <HomeIcon />,
      link: "/"
    },
    {
      text: "My Worksheets",
      icon: <ListAltIcon />,
      link: "/worksheets"
    },
    {
      text: "Worksheets",
      icon: <StoreIcon />,
      link: "/store"
    },
    {
      text: "Game Design Challenge",
      icon: <GamesIcon />,
      link: "/gameDesignStore"
    },
    // {
    //   text: "Events",
    //   icon: <EventNoteIcon />,
    //   link: "/events"
    // },
    {
      text: "Profile",
      icon: <PersonIcon />,
      link: "/profile"
    },
    {
      text: "FAQs",
      icon: <QuestionAnswerOutlined />,
      link: "/faq"
    },
    {
      text: "Logout",
      icon: <ExitToAppIcon />,
      link: "/"
    }
  ];

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <div style={{ margin: "30px 0", display: "flex", justifyContent: "center" }}>
        {tutorData?.name ?
          <div className={classes.logoInitials}>{getInitials(tutorData.name)}</div>
          :
          <WallpaperIcon style={{ fontSize: "100px", color: "#757575" }} />
        }
      </div>
      <List>
        {itemsList.map((item) => {
          const { text, icon, link } = item;
          return text !== "Logout" ?
            (
              <ListItem button key={text} component={Link} to={link} className={`/${history.location.pathname.split("/")[1]}` === link ? classes.isActive : ""}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={text} />
              </ListItem>
            )
            :
            (
              <ListItem button key={text} onClick={handleLogout}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={text} />
              </ListItem>
            )

        })}
      </List>

      {tutorData?.subscriptionType !== "platinum" &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => history.push(`/subscribe`)}
            startIcon={<AddBox />}
          >
            Upgrade
          </Button>
        </div>
      }

      <Typography
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '12px' }}
      >
        {"You're currently on the "}
        {tutorData?.subscriptionType}
        {" plan"}
      </Typography>

    </div>
  );

  useEffect(() => {
    if (!tutorData?.completedWalkthrough && location.pathname === "/") setWalkthroughActive(true);
  }, [tutorData?.completedWalkthrough, location]);

  return (
    <div className={classes.root}>
      <Walkthrough isOverlayActive={isOverlayActive} setWalkthroughActive={setWalkthroughActive} />
      <VoiceflowChat />
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar color="inherit">
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img
            src="/img/arludo_logo_horizontal_notagline.jpg"
            alt="Arludo Logo"
            style={{ maxHeight: 40 }}
          />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
            onClick={handleDrawerToggle}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Route path="/" component={HomePage} exact={true} />
        <Route path="/worksheets" component={WorksheetsPage} exact={true} />
        <Route path="/worksheets/:summaryId" component={SummaryPage} exact={true} />
        <Route path="/store" component={StorePage} exact={true} />
        <Route path="/gameDesignStore" component={GameDesignStorePage} exact={true} />
        <Route path="/events" component={EventPage} exact={true} />
        <Route path="/profile" component={ProfilePage} exact={true} />
        <Route path="/store/:type/:id" component={StoreWorksheetPage} exact={true} />
        <Route path="/store/:type/:id/checkout" component={StoreCheckoutPage} exact={true} />
        <Route path="/subscribe" component={SubscriptionPage} exact={true} />
        <Route path="/faq" component={FaqPage} exact={true} />
        <Route
          path="/quiz/:quizId&:worksheetId&:tutorId&:studentName"
          component={ViewQuizPage}
        />
      </main>
    </div>
  );
};

export default MainRoute;