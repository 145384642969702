import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";


export const HadDataRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const hasData = useSelector((state) => !!state.tutor.tutor.email);

  return (
    <Route {...rest} component={(props) => (
      isAuthenticated ?
        hasData ? (
          <Redirect to="/" />
        )
          :
          <Component {...props} />
        : (
          <Redirect to="/login" />
        )
    )} />
  )
};

export default HadDataRoute;
