import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Box, TextField } from "@material-ui/core";

import Modal from "./Modal";
import { startAddStudent } from "../actions/tutor";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(3, 0, 2),
    width: 300,
    display: "block",
    marginBottom: 100,
    color: "white",
  },
  checkbox: {
    display: "block",
    color: "#737373",
  },
}));

const AddStudent = (props) => {
  const {setStudentId} = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [studentName, setStudentName] = useState("");
  const [open, setOpen] = useState(false);

  const handleAddStudent = async () => {
    if (!studentName) {
      toast.error("Please provide the student name");
    } else {
      setOpen(true);
      const res = await dispatch(startAddStudent(studentName));
      if (res.message === "success") {
        if (setStudentId) {
          setStudentId([res.studentId , res.studentName]);
        }
        setStudentName("");
        setOpen(false);
      } else {
        toast.error(res.message);
        setOpen(false);
      }
    }
  };

  return (
    <Box style={{ width: "max-content", margin: "auto" }}>
      <Typography
        variant="h5"
        style={{ fontSize: "18px", marginTop: 20, marginBottom: 5 }}
      >
        Student Name*:
      </Typography>
      <TextField
        variant="outlined"
        placeholder="Steve..."
        style={{ width: 300 }}
        value={studentName}
        onChange={(e) => setStudentName(e.target.value)}
      />
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={() => handleAddStudent()}
      >
        Add Student
      </Button>
      <Modal open={open} />
    </Box>
  );
};

export default AddStudent;
