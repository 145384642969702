import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  CssBaseline,
  Typography,
  Box,
} from '@material-ui/core';

import AddStudent from "../../components/AddStudent.js";
import ChangePassword from "../../components/ChangePassword.js";
import PersonalDetails from "../../components/PersonalDetails.js";
import ChangeSubscription from "../../components/ChangeSubscription.js";

const useStyles = makeStyles((theme) => ({
  title: {
    borderBottom: "1px solid #BDBDBD",
    margin: "30px 0"
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    width: 200,
    color: "white",
  },
  title2: {
    width: 250,
    fontWeight: "bold",
    alignSelf: "center",
  },
  checkbox: {
    display: "block",
    color: "#737373",
  },
}));


const ProfilePage = () => {
  const classes = useStyles();
  const tutorData = useSelector((state) => state.tutor.tutor);

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <Typography component="h1" variant="h5" color="textSecondary" className={classes.title}>
        Students
      </Typography>
      <Box>
        {tutorData.students.length > 0 ?
          <Box>
            {tutorData.students.map((student, i) => (
              <Typography key={i} gutterBottom variant="h6" color="textSecondary" style={{ fontWeight: "bold" }}>
                {i + 1} - {student.studentName}
              </Typography>
            ))}
          </Box>
          :
          <Typography gutterBottom variant="body1" color="textSecondary" component="p">
            No students available. Please add a student.
          </Typography>
        }
        <div style={{marginLeft: 0, width: "min-content"}}>
          <AddStudent />
        </div>
      </Box>
      <Typography component="h1" variant="h5" color="textSecondary" className={classes.title}>
        Personal Details
      </Typography>
      <PersonalDetails />

      <Typography component="h1" variant="h5" color="textSecondary" className={classes.title}>
        Subscription Status
      </Typography>
      <ChangeSubscription />

      <Typography component="h1" variant="h5" color="textSecondary" className={classes.title}>
        Login Details
      </Typography>
      <ChangePassword />

    </Container>
  );
}


export default ProfilePage;