const authDefaultState = {
  isAuthenticated: null,
  email: null
};

const authReducer = (state = authDefaultState, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        isAuthenticated: true,
        email: action.email
      }

    case "SET_NO_USER":
      return {
        isAuthenticated: false,
        email: null
      }

    case "SET_LOGIN":
      return {
        isAuthenticated: true,
        email: action.email
      }

    case "SET_LOGOUT":
      return authDefaultState

    default:
      return state;
  }
};

export default authReducer;