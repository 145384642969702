import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, Typography, Container, Box } from "@material-ui/core";
import Loading from "../../../components/Loading";
import { getSummary } from "../../../actions/worksheet";

const useStyles = makeStyles((theme) => ({
  main: {
    textAlign: "center",
    marginBottom: 20,
  },
  body: {
    borderTop: "1px solid #dedcdc",
    padding: "20px 0",
    marginBottom: 50,
  },
  content: {
    backgroundColor: "#fff",
    boxShadow: "0px 2px 4px #dadada",
    padding: "40px 30px",
    borderRadius: 5,
  },
  icon: {
    fontSize: 56,
    marginBottom: 10,
    color: theme.palette.primary.main,
  },
  name: {
    fontWeight: "bold",
    display: "inline-block",
    marginTop: 5,
    backgroundColor: "#F1F1F1",
    borderRadius: 5,
    padding: 8,
  },
  nameText: {
    color: theme.palette.secondary.main,
    marginLeft: 5,
    fontWeight: "bold",
  },
}));

const SummaryPage = (props) => {
  const classes = useStyles();
  const [summary, setSummary] = useState([]);
  const { summaryId } = props.match.params;
  const { worksheetId, name, studentName, coverImage } = props.location.state;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const res = await getSummary(summaryId, worksheetId);
      setSummary(JSON.parse(res.data));
      setLoading(false);
    })();
  }, [worksheetId, summaryId]);

  return loading ? (
    <Loading />
  ) : (
    <Container>
      <CssBaseline />
      <Box className={classes.main}>
        <img
          style={{ borderRadius: "5px" }}
          height="100px"
          src={coverImage}
          alt="Cover"
        />
        <Typography
          color="textSecondary"
          style={{ fontWeight: "bold", fontSize: 20, marginTop: 5 }}
        >
          {name}
        </Typography>
        <Box>
          <Typography color="textSecondary" className={classes.name}>
            Student Name:{" "}
            <span className={classes.nameText}>{studentName}</span>
          </Typography>
        </Box>
      </Box>
      <Box className={classes.body}>
        <Box className={classes.content}>
          {summary.map((entry, i) => (
            <Box style={{ marginBottom: 30 }} key={i}>
              <Typography color="textSecondary" style={{ marginBottom: 5 }}>
                Question: {entry.question}
              </Typography>
              <Typography color="textSecondary" style={{ marginBottom: 5 }}>
                Answer: {entry.answer}
              </Typography>
              <Typography color="textSecondary" style={{ marginBottom: 5 }}>
                Feedback: {entry.feedback}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default SummaryPage;
