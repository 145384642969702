import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container
} from '@material-ui/core';
import Copyrights from "../../components/Copyrights";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';

import { startConfirmPasswordReset, startSetForgotPassword } from '../../actions/auth';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: "url('/img/signup-page.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  logo: {
    maxHeight: 80,
    marginTop: "30%",
    marginBottom: 60
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  subTitle: {
    fontSize: "14px",
    textAlign: "center",
    marginTop: 10
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "#fff"
  },
}));

const ResetPasswordConfirmPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState(props.history.location.state?.email || "");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!email) {
        toast.error("Please provide the email address");
      } else if (!code) {
        toast.error("Please provide the code emailed to you");
      } else if (!password) {
        toast.error("Please provide the new password");
      } else if (!confirmPassword) {
        toast.error("Please confirm your Password");
      } else {
        if (password !== confirmPassword) {
          toast.error("Password does not match");
        } else {
          await startConfirmPasswordReset(email, code, password);
          history.push("/login");
        };
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleResendCode = async () => {
    try {
      if (!email) {
        toast.error("Please provide the email address");
      } else {
        await startSetForgotPassword(email);
        toast.success("A new code has been sent to your email address");
      }
    } catch (error) {
      toast.error(error.message);
    };
  };

  return (
    <Box className={classes.background}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            src="/img/arludo_logo_horizontal_notagline.jpg"
            alt="Arludo Logo"
            className={classes.logo}
          />
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant="h5">
            Reset Your Password
          </Typography>
          <Typography className={classes.subTitle}>
            A code has been sent to your email to reset your password
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="code"
              label="Code"
              type="text"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Reset
            </Button>
            <Grid container>
              <Grid item xs>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: "14px", cursor: "pointer", display: "inline-block" }}
                  onClick={handleResendCode}
                >
                  Resend Code
                </Typography>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyrights />
        </Box>
      </Container>
    </Box>
  );
};

export default ResetPasswordConfirmPage;