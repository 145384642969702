import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  CssBaseline,
  Grid,
  Typography,
  Container,
  Button
} from '@material-ui/core';
import LockIcon from "@material-ui/icons/Lock";

import { useSelector } from "react-redux";
import { checkSubscriptionType } from "../../actions/tutor";

import {getParentEvent} from "../../actions/event";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "flex",
  },
  item: {
    display: "inline-block",
    width: "100%",
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: "0px 2px 4px #dadada",
    textAlign: "center",
    borderRadius: 3,
    transition: "0.2s",
    padding: 20,
    textDecoration: "none",
    "&:hover": {
      boxShadow: "0px 2px 10px #d6d6d6",
      transform: "scale(1.015)"
    }
  },
}));

const upgradePanel = (subscriptionType, theme, classes) => {
  const subscribedPlan = checkSubscriptionType(subscriptionType)
  switch (subscribedPlan.type) {
    case "pro":
      return (
        <Grid item xs={12} md={6} className={classes.grid}>
          <Link to="/subscribe" className={classes.item}>
            <Typography
              color="textSecondary"
              variant="h6"
              style={{ color: theme.palette.secondary.main, fontWeight: "bold", marginBottom: 10 }}
            >
              Upgrade to have our scientists mark their worksheet and respond to your child’s questions.
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
              <Typography
                variant="subtitle2"
                style={{ fontWeight: "normal", textAlign: "left", color: theme.palette.primary.main }}
              >
                Arludo Platinum<br />
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ fontWeight: "normal", textAlign: "left" }}
              >
                Everything in Pro plus:<br />
                •      Our scientists mark your child’s worksheets<br />
                •      Request and vote on the next games we create<br />
                •      Gain access to our new games before anyone else<br />
              </Typography>
            </div>
            <Button variant="contained" color="primary" style={{ marginTop: '20px' }}>
              Upgrade to Platinum
            </Button>
          </Link>
        </Grid>
      )
    case "platinum":
      return (
        <Grid item xs={12} md={6} className={classes.grid}>
          <div className={classes.item}>
            <Typography
              color="textSecondary"
              variant="h6"
              style={{ color: theme.palette.secondary.main, fontWeight: "bold", marginBottom: 10 }}
            >
              Have any questions?
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal", textAlign: "left" }}
            >
              You have access to priority support. You can reach out at{" "}
              <a href="mailto:info@arludo.com" style={{ color: theme.palette.primary.main }}>
                info@arludo.com
              </a>{" "}
              if you have any questions. <br /><br />
              Additionally, don’t forget that:<br />
              •      Our scientists mark your child’s worksheets<br />
              •      Your child can chat with our scientists in Discord if they have any questions.<br /><br />
            </Typography>
            <a href="https://discord.gg/bb5mPvBtc2" >
              <Button variant="contained" color="primary">
                Join Discord Here
              </Button>
            </a>
          </div>
        </Grid>
      )
    default:
      return (
        <Grid item xs={12} md={6} className={classes.grid}>
          <Link to="/subscribe" className={classes.item}>
            <Typography
              color="textSecondary"
              variant="h6"
              style={{ color: theme.palette.secondary.main, fontWeight: "bold", marginBottom: 10 }}
            >
              Are you getting the most out of your subscription?
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  style={{ color: theme.palette.secondary.main, fontWeight: "normal", textAlign: "left" }}
                >
                  Arludo Pro <br />
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ fontWeight: "normal", textAlign: "left" }}
                >
                  •      Access over 30 different lessons in science and maths<br />
                  •      Chat with our scientists in Discord<br />
                  •      Access to our Parent Facebook Community
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  style={{ fontWeight: "normal", textAlign: "left", marginLeft: "15px", color: theme.palette.primary.main }}
                >
                  Arludo Platinum<br />
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ fontWeight: "normal", textAlign: "left", marginLeft: "15px" }}
                >
                  Everything in Pro plus:<br />
                  •      Our scientists mark your child’s worksheets<br />
                  •      Request and vote on the next games we create<br />
                  •      Gain access to our new games before anyone else<br />
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Button variant="contained" color="secondary" style={{ marginTop: '20px' }}>
                  Upgrade to Pro
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" color="primary" style={{ marginTop: '20px' }}>
                  Upgrade to Platinum
                </Button>
              </Grid>


            </Grid>
          </Link>
        </Grid>
      )
  }
}

const worksheetPanel = (subscriptionType, worksheets, theme, classes) => {
  let completed = 0;
  let marked = 0;
  worksheets.forEach(async (sheet) => {
    if (sheet.completedAt) {
      completed++;
      // check if summary exists 
      if (sheet.markedAt) {
        marked++;
      }
      //   sheet.summaries.forEach(async (summary) => {
      //   const summaryData = await getSummary(summary.id);
      //   if (summaryData.name === sheet.studentName) {
      //     // has been marked 
      //     marked++;
      //   }
      // });

    }
  });
  return (
    <Grid item xs={12} md={6} className={classes.grid}>
      <Link to="/worksheets" className={classes.item}>
        <Grid container>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal", textAlign: "center", fontSize: 20, marginTop: '30px' }}
            >
              Worksheets Added <br />
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal", textAlign: "center", fontSize: 40 }}
            >
              {worksheets?.length}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal", textAlign: "center", fontSize: 20, marginTop: '30px', color: theme.palette.primary.main }}
            >
              Worksheets Completed<br />
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal", textAlign: "center", fontSize: 40, color: theme.palette.primary.main }}
            >
              {completed}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal", textAlign: "center", fontSize: 20, marginTop: '30px', color: theme.palette.secondary.main }}
            >
              Worksheets Marked <br />
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal", textAlign: "center", fontSize: 40, color: theme.palette.secondary.main }}
            >
              {checkSubscriptionType(subscriptionType).type === "platinum" ? marked : <LockIcon style={{ fontSize: 70, marginTop: '15px' }} />}
            </Typography>
          </Grid>
          {
            checkSubscriptionType(subscriptionType) ? (
              <Grid item xs={6}>
              </Grid>
            ) : (
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  style={{ fontWeight: "normal", textAlign: "center", fontSize: 20, marginTop: '30px' }}
                >
                  To unlock scientists marking your worksheets <br />
                </Typography>
                <Link to="/subscribe">
                  <Button variant="contained" color="secondary" style={{ marginTop: '10px' }}>
                    Upgrade to Platinum
                  </Button>
                </Link>
              </Grid>
            )
          }

        </Grid>
      </Link>
    </Grid>
  )

}




const HomePage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { subscriptionType } = useSelector((state) => state.tutor.tutor);
  const { worksheets } = useSelector((state) => state.tutor.tutor);

  const [welcome, setWelcome] = React.useState("");
  const [news, setNews] = React.useState("");

    
  useEffect(() => {
    (async () => {
      const parentEvent = await getParentEvent();
      setWelcome(parentEvent.data.welcome);
      setNews(parentEvent.data.news);
    })();

  }, []);

  return (
    <Container>
      <CssBaseline />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} className={classes.grid}>
          <div className={classes.item}>
            <Typography
              color="textSecondary"
              variant="h6"
              style={{ color: theme.palette.secondary.main, fontWeight: "bold", marginBottom: 10 }}
            >
              WELCOME TO ARLUDO
            </Typography>
            <div className={classes.text} dangerouslySetInnerHTML={{ __html: welcome }}>
            </div>
            {/* <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal", textAlign: "left" }}
            >
              Get ready to learn in a completely different way! <br /><br />
              On the left, you will find the ‘Worksheets’ tab. Here you can find the different lessons. Not sure which worksheet to start with? We recommend ‘How do prey protect themselves from predators?’ <br /><br />
              Not sure where to start? Our chatbot in the bottom right can help you find lessons you are interested in. Just type in a topic and Arlo will suggest a worksheet for you! <br /><br />
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal", textAlign: "left" }}
            >
              If you have any questions, don’t hesitate to get in touch at
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal", textAlign: "left", color: theme.palette.primary.main }}
            >
              <a href="mailto:info@arludo.com" style={{ color: theme.palette.primary.main }}>
                info@arludo.com
              </a>
            </Typography> */}
          </div>
        </Grid>
        {/* {worksheetsPanel(subscriptionType, worksheets, theme, classes)} */}
        {worksheetPanel(subscriptionType, worksheets, theme, classes)}
        {upgradePanel(subscriptionType, theme, classes)}
        <Grid item xs={12} md={6} className={classes.grid}>
          <div className={classes.item}>
            <Typography
              color="textSecondary"
              variant="h6"
              style={{ color: theme.palette.secondary.main, fontWeight: "bold", marginBottom: 10 }}
            >
              NEWS
            </Typography>
            <div className={classes.text} dangerouslySetInnerHTML={{ __html: news }}>
            </div>
            {/* <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal", textAlign: "left" }}
            >
              Check out our free virtual incursions we have scheduled in 2024! Come join us for some great lessons with Arludo's Scientists.<br /><br />
            </Typography>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: "normal", textAlign: "left", color: theme.palette.primary.main }}
            >
              <a href="https://dartlearning.org.au/provider/arludo/" style={{ color: theme.palette.primary.main }}>
                Find out more here.
              </a>
            </Typography> */}
          </div>
        </Grid>
      </Grid>
    </Container >
  )
};

export default HomePage;