import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import Modal from "./Modal";
import { startUpdateTutorData } from "../actions/tutor";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(3, 0, 2),
    width: 200,
    color: "white",
  },
  title: {
    width: 250,
    fontWeight: "bold",
    alignSelf: "center",
  },
  checkbox: {
    display: "block",
    color: "#737373",
  },
}));

const PersonalDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tutorData = useSelector((state) => state.tutor.tutor);
  const [editDetails, setEditDetails] = useState(false);
  const [tutorName, setTutorName] = useState("");
  const [schoolYear, setSchoolYear] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTutorName(tutorData.name);
    setSchoolYear(tutorData.schoolYear);
  }, [tutorData]);

  const handleSaveChanges = async () => {
    if (!tutorName) {
      toast.error("Please provide your full name");
    } else if (schoolYear.length < 1) {
      toast.error("Please select at least one year you are interested in");
    } else {
      setOpen(true);
      const res = await dispatch(startUpdateTutorData(tutorName, schoolYear));
      if (res === "ok") {
        toast.success("The changes have been saved successfully");
        setEditDetails(false);
        setOpen(false);
      } else {
        toast.error("Something went wrong. Please try again later.");
        setOpen(false);
      }
    }
  };

  const handleEditDetails = () => {
    setEditDetails(true);
  };

  const handleSchoolYearChange = (event) => {
    if (event.target.checked) {
      setSchoolYear([...schoolYear, event.target.name]);
    } else {
      setSchoolYear(schoolYear.filter((item) => item !== event.target.name));
    }
  };

  return (
    <Box style={{ width: "max-content" }}>
      <Box style={{ display: "flex" }}>
        <Typography
          gutterBottom
          variant="h6"
          color="textSecondary"
          className={classes.title}
        >
          Full Name:
        </Typography>
        {editDetails ? (
          <TextField
            variant="outlined"
            margin="normal"
            name="name"
            autoComplete="fname"
            value={tutorName}
            onChange={(e) => setTutorName(e.target.value)}
          />
        ) : (
          <Typography gutterBottom variant="h6" color="textSecondary">
            {tutorData.name}
          </Typography>
        )}
      </Box>
      <Box style={{ display: "flex" }}>
        <Typography
          gutterBottom
          variant="h6"
          color="textSecondary"
          className={classes.title}
        >
          School:
        </Typography>
        <Typography gutterBottom variant="h6" color="textSecondary">
          {tutorData.institution}
        </Typography>
      </Box>
      <Box style={{ display: "flex" }}>
        <Typography
          gutterBottom
          variant="h6"
          color="textSecondary"
          className={classes.title}
        >
          Country:
        </Typography>
        <Typography gutterBottom variant="h6" color="textSecondary">
          {tutorData.country}
        </Typography>
      </Box>
      <Box style={{ display: "flex" }}>
        <Typography
          gutterBottom
          variant="h6"
          color="textSecondary"
          className={classes.title}
        >
          Postcode:
        </Typography>
        <Typography gutterBottom variant="h6" color="textSecondary">
          {tutorData.postcode}
        </Typography>
      </Box>
      <Box style={{ display: "flex" }}>
        <Typography
          gutterBottom
          variant="h6"
          color="textSecondary"
          className={classes.title}
        >
          School Years:
        </Typography>
        {editDetails ? (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={schoolYear.includes("3-6")}
                  onChange={handleSchoolYearChange}
                  name="3-6"
                  color="primary"
                />
              }
              className={classes.checkbox}
              label="3-6"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={schoolYear.includes("7-10")}
                  onChange={handleSchoolYearChange}
                  name="7-10"
                  color="primary"
                />
              }
              className={classes.checkbox}
              label="7-10"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={schoolYear.includes("11-12")}
                  onChange={handleSchoolYearChange}
                  name="11-12"
                  color="primary"
                />
              }
              className={classes.checkbox}
              label="11-12"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={schoolYear.includes("university")}
                  onChange={handleSchoolYearChange}
                  name="university"
                  color="primary"
                />
              }
              className={classes.checkbox}
              label="University"
            />
          </Box>
        ) : (
          <Box>
            {tutorData.schoolYear.map((year, i) => (
              <Typography
                key={i}
                gutterBottom
                variant="h6"
                color="textSecondary"
                style={{ textTransform: "capitalize" }}
              >
                {year}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        {editDetails ? (
          <Button
            // type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => handleSaveChanges()}
          >
            Save Changes
          </Button>
        ) : (
          <Button
            // type="submit"
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => handleEditDetails()}
          >
            Edit Details
          </Button>
        )}
      </Box>
      <Modal open={open} />
    </Box>
  );
};

export default PersonalDetails;
