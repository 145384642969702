export const schoolYearSortAlgorithm = (a, b) => {
  const schoolYears = {
    "3-6": 1,
    "7-10": 2,
    "11-12": 3,
    "university": 4
  }
  if (schoolYears[a] < schoolYears[b]) return -1;
  else if (schoolYears[a] > schoolYears[b]) return 1
  else return 0;
}