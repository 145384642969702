import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";


const AuthRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <Route {...rest} component={(props) => (
      isAuthenticated ? (
        <Redirect to="/" />
      ) : (
        <Component {...props} />
      )
    )} />
  )
}

export default AuthRoute;