import { v4 as uuid } from "uuid";
import { TUTOR_SERVICE_URL } from "./constant";
import axios from "axios";

export const setTutorData = (tutor) => ({
  type: "SET_TUTOR",
  tutor,
});

export const setNoTutorData = () => ({
  type: "SET_NO_TUTOR",
});

export const startSetTutorData = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${TUTOR_SERVICE_URL}/tutor`);
      if (res.data.tutor?.tutorType) {
        let tutorData = {
          ...res.data.tutor,
          worksheets: [...res.data.worksheets],
          ...(res.data.paymentMethod && {
            paymentMethod: res.data.paymentMethod,
          }),
        };
        dispatch(setTutorData(tutorData));
      } else {
        console.error("No tutor data");
        throw new Error("No tutor data");
      }
    } catch (error) {
      if (error.response?.status === 403) {
        dispatch(setTutorData({}));
      } else {
        dispatch(setNoTutorData());
      }
    }
  };
};

export const createTutorData = (data) => {
  return async (dispatch) => {
    const res = await axios.post(`${TUTOR_SERVICE_URL}/tutor`, data);
    if (res.data.tutor?.tutorType) {
      dispatch(setTutorData(res.data.tutor));
    }
    return res;
  };
};

export const updateTutorData = (tutorName, schoolYear) => ({
  type: "UPDATE_TUTOR",
  tutorName,
  schoolYear,
});

export const startUpdateTutorData = (tutorName, schoolYear) => {
  return async (dispatch) => {
    const res = await axios.put(`${TUTOR_SERVICE_URL}/tutor`, {
      tutorName,
      schoolYear,
    });
    if (res.data.message === "success") {
      dispatch(updateTutorData(tutorName, schoolYear));
      return "ok";
    } else {
      return "error";
    }
  };
};

export const addStudent = (studentName, studentId) => ({
  type: "ADD_STUDENT",
  studentName,
  studentId,
});

export const startAddStudent = (studentName) => {
  return async (dispatch) => {
    const studentId = uuid();
    const { data } = await axios.post(`${TUTOR_SERVICE_URL}/add-student`, {
      studentName,
      studentId,
    });
    if (data.message === "success") {
      dispatch(addStudent(studentName, studentId));
    }
    return data;
  };
};

export const clearTutorData = () => ({
  type: "CLEAR_TUTOR",
});

export const getTutor = async () => {
  try {
    const res = await axios.get(`${TUTOR_SERVICE_URL}/tutor`);
    return res.data;
  } catch (e) {
  }
};

export const checkCoupon = async (coupon, priceId) => {
  try {
    const { data: resData } = await axios.get(`${TUTOR_SERVICE_URL}/subscription/checkCoupon?coupon=${coupon}&priceId=${priceId}`);
    return resData;
  } catch (error) {
    return error.response.data;
  }
}

export const createSubscription = async (subscriptionData) => {
  try {
    const { data: resData } = await axios.post(`${TUTOR_SERVICE_URL}/subscription`, subscriptionData);

    return resData;
  } catch (error) {
    throw error.response.data;
  }
}

export const checkSubscriptionType = (subscriptionType) => {
  if (subscriptionType === "pro") {
    return {
      type: "pro"
    }
  } else if (subscriptionType === "platinum") {
    return {
      type: "platinum"
    }
  } else {
    return false;
  }
}