import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, FormControl, Grid, Select, Typography, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import AddStudent from "./AddStudent";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { schoolYearSortAlgorithm } from "../selectors/schoolYearSortAlgorithm";
import { checkSubscriptionType } from "../actions/tutor";
import LockIcon from "@material-ui/icons/Lock";
import { checkoutSheet, finishWalkthrough, getIBWorksheets } from "../actions/worksheet";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  grid: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    display: "flex",
    borderBottom: "1px solid #BDBDBD",
    padding: "2px 0",
    margin: "30px 0",
    justifyContent: "space-between"
  },
  name: {
    display: "-webkit-box",
    height: "3em",
    lineHeight: "1em",
    overflow: "hidden",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
  },
  description: {
    overflow: "hidden",
    WebkitLineClamp: 3,
    display: "-webkit-box",
    boxOrient: "vertical",

  },
  button: {
    color: "white",
    textTransform: "none",
    overflow: "hidden",
    fontSize: "0.82rem",
  },
  category: {
    color: theme.palette.primary.main,
    display: "inline-block",
  },
  game: {
    color: theme.palette.secondary.main,
    display: "inline-block",
  },
  selectRoot: {
    padding: 6,
    '&:focus': {
      backgroundColor: 'inherit'
    }
  }
}));

const Walkthrough = (props) => {
  const { isOverlayActive, setWalkthroughActive } = props;
  const [currentSection, setCurrentSection] = useState(0);
  // const [selectedAgeGroup, setSelectedAgeGroup] = useState("");
  const [cards, setCards] = useState([]);
  const [assignedStudent, setAssignedStudent] = useState("");
  const tutorData = useSelector((state) => state.tutor.tutor, shallowEqual);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleAdd = async (sheet) => {
    const { id, name, price, coverImage } = sheet;

    const results = await dispatch(
      checkoutSheet(
        id,
        assignedStudent[1],
        assignedStudent[0],
        name,
        coverImage,
        price === 0
      )
    );
    if (results === "success") {
      openNextSection()
    } else {
      toast.error(results);
    }
  };

  const openNextSection = (event) => {
    if (currentSection === 0) {
      setCurrentSection(1);
    } else if (currentSection === 1) {
      setCurrentSection(2);
    } else if (currentSection === 2) {
      setCurrentSection(3);
    } else if (currentSection === 3) {
      setCurrentSection(4);
    }
  }

  const openPrevSection = (event) => {
    if (currentSection === 1) {
      setCurrentSection(0);
    } else if (currentSection === 2) {
      setCurrentSection(1);
    } else if (currentSection === 3) {
      setCurrentSection(2);
    }
  }

  const finishWT = async (e) => {
    const results = await dispatch(finishWalkthrough());

    setWalkthroughActive(false)
  }

  const sectionList = [
    (
      <div>
        <h1 style={{ color: "#222", textAlign: "center" }}>Welcome to Arludo!</h1>
        <h3 style={{ color: "#222", textAlign: "center" }}>
          We're so excited that you've decided to learn and teach science in a new and engaging way.
          Let's step through some simple setup to get you started.
        </h3>
      </div>
    ),
    (
      <div>
        <h1 style={{ color: "#222", textAlign: "center" }}>Let's add your learner</h1>
        <h3 style={{ color: "#222", textAlign: "center" }}>
          You'll be able to add multiple learners in your profile, but let's get you started here.
          Enter the name of your first learner below:
        </h3>
        <Box>
          <AddStudent setStudentId={setAssignedStudent} />
        </Box>
      </div>
    ),
    (
      <div>
        <h1 style={{ color: "#222", textAlign: "center" }}>Let's add your first worksheet</h1>
        <h3 style={{ color: "#222", textAlign: "center" }}>
          To get you started we suggest you start with our favourite
          worksheet. Select the age group of your learner below:
          <Grid container spacing={2}>
            {
              cards && cards.sort(schoolYearSortAlgorithm).map(({ id, name, description, price, coverImage, ageGroup, templateId, game }) => (
                <Grid item xs={12} sm={4} key={id + " " + templateId}>
                  <Card>
                    <CardActionArea disabled>
                      <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        image={coverImage}
                        title="Contemplative Reptile"
                        style={{ width: "inherit", height: "inherit" }}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="h2" className={classes.name}>
                          {name}
                        </Typography>
                        <div>
                          {game ?
                            <Typography gutterBottom variant="body1" color="textSecondary" component="h2" className={classes.game}>
                              Game: {game}
                            </Typography>
                            : void 0
                          }
                        </div>

                        <div>
                          <Typography gutterBottom variant="body1" color="textSecondary" component="h2" className={classes.category}>
                            {ageGroup === 'university' ? void 0 : `Grades: `}
                            {ageGroup}
                          </Typography>
                        </div>

                        <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
                          {description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions style={{ justifyContent: "space-evenly", marginBottom: "10px" }}>
                      <Button
                        size="medium"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={() => handleAdd({ id, name, description, price, coverImage, ageGroup, templateId, game })}>
                        Add Worksheet
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            }
          </Grid>
        </h3>
      </div>
    ), (
      <div>
        <h1 style={{ color: "#222", textAlign: "center" }}>You're ready to go!</h1>
        <h3 style={{ color: "#222", textAlign: "center" }}>
          Everything is now set for your first learner to start!
          If you have any questions about our worksheets, you can ask our chatbot
          in the bottom right of your screen,
          or feel free to email us at <a href="mailto:info@arludo.com">info@arludo.com</a>, 
          or join our <a href="https://www.facebook.com/groups/970681117601502">Facebook Group here</a>.
          We're always here to help people who want to learn science!
        </h3>
      </div>
    )
  ]

  useEffect(() => {
    (async () => {
      const getCards = await getIBWorksheets();
      setCards(getCards);
    })();
  }, [])

  useEffect(() => {
    if (assignedStudent) {
      setCurrentSection(2);
    }
  }, [assignedStudent])

  return (
    <div
      style={{
        position: "absolute",
        width: "100vw",
        height: "100vh",
        zIndex: 9000,
        bottom: 0,
        right: 0,
        backgroundColor: "rgba(0,0,0,0.85)",
        display: isOverlayActive ? "flex" : "none",
      }}
    >
      <div style={{
        backgroundColor: "#ddd",
        boxShadow: "0px 2px 4px #dadada",
        textAlign: "center",
        borderRadius: 3,
        transition: "0.2s",
        padding: 20,
        textDecoration: "none",
        width: "40vw",
        margin: "auto"
      }}>
        {sectionList[currentSection]}
        <Grid>
          {
            currentSection !== 0 ? (
              <Button variant="contained" color="secondary" style={{ margin: '20px' }} onClick={openPrevSection}>
                Back
              </Button>
            ) : (
              <Button variant="contained" color="secondary" style={{ margin: '20px' }} onClick={() => setWalkthroughActive(false)}>
                Close
              </Button>
            )
          }
          {
            currentSection !== 3 ? (
              <Button variant="contained" color="primary" style={{ margin: '20px' }} onClick={openNextSection}>
                Next
              </Button>
            ) : (
              <Button variant="contained" color="primary" style={{ margin: '20px' }} onClick={finishWT}>
                Done
              </Button>
            )
          }

        </Grid>
      </div>
    </div>
  )
}

export default Walkthrough;