import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container
} from '@material-ui/core';
import Copyrights from '../../components/Copyrights';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';

import { startSetRegister } from '../../actions/auth';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: "url('/img/signup-page.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  logo: {
    maxHeight: 80,
    marginTop: "30%",
    marginBottom: 60
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "#fff"
  },
}));

const RegisterPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!email) {
        toast.error("Please provide the email address");
      } else if (!password) {
        toast.error("Please provide the password");
      } else if (!confirmPassword) {
        toast.error("Please confirm your password");
      } else {
        if (password !== confirmPassword) {
          toast.error("Password does not match");
        } else {
          await startSetRegister(email, password);
          history.push("/confirmemail", { email, password });
        };
      };
    } catch (error) {
      toast.error(error.message);
    };
  };

  return (
    <Box className={classes.background}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            src="/img/arludo_logo_horizontal_notagline.jpg"
            alt="Arludo Logo"
            className={classes.logo}
          />
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant="h5">
            Create Your Free Account
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Typography
                  variant="h5"
                  color="textSecondary"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  onClick={() => history.push("/login")}
                >
                  Already have an account? Sign in
                </Typography>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <Copyrights />
        </Box>
      </Container>
    </Box>
  );
};

export default RegisterPage;
