import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import MainRoute from "./MainRoute";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const hasData = useSelector((state) => !!state.tutor.tutor.email);

  return (
    <Route {...rest} component={(props) => (
      isAuthenticated ?
        hasData ? (
          <MainRoute {...props} />
        )
          :
          <Redirect to="/tutordetails" />
        : (
          <Redirect to="/login" />
        )
    )} />
  )
};

export default PrivateRoute;
