import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Button,
  CssBaseline,
  Grid,
  Typography,
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import LockIcon from "@material-ui/icons/Lock";
import Loading from '../../../components/Loading';

import { getWorksheet } from '../../../actions/worksheet';

import { checkSubscriptionType } from '../../../actions/tutor';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 345,
    }
  },
  grid: {
    display: "block",
    alignItems: "center",

  },
  title: {
    borderBottom: "1px solid #BDBDBD",
    margin: "30px 0"
  },
  name: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  description: {
    overflow: "hidden",
    WebkitLineClamp: 3,
    display: "box",
    boxOrient: "vertical"
  },
  button: {
    color: "white",
    width: 200,
    textTransform: "none"
  },
  category: {
    padding: "5px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    display: "inline-block",
    borderRadius: "5px",
    textTransform: "capitalize"
  }
}));

const StoreWorksheetPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = props.match.params;
  const [data, setData] = useState({});
  const [worksheets, setWorksheets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPremium, setIsPremium] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const tutorData = useSelector((state) => state.tutor.tutor);

  useEffect(() => {
    (async () => {
      const worksheetData = await getWorksheet(id);
      if (!worksheetData) {
        return;
      }
      setData(worksheetData.sheet);
      setWorksheets(worksheetData.sheets);

      setLoading(false);
      
      if (checkSubscriptionType(tutorData.subscriptionType)) {
        setIsPremium(true);
        setIsAvailable(true);
      }
      if (worksheetData.sheet.price === 0) {
        setIsAvailable(true);
      }
    })()
  }, [id]);

  console.log(data);

  return (
    loading ?
      <Loading /> :
      (
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} style={{ display: "flex", justifyContent: "center", marginTop: "10px", marginBottom: "40px" }}>
              <Carousel>

                {data && data.additionalImages &&
                  data.additionalImages.map((imageUrl, index) => (
                    <Box key={index}>
                      <CardMedia
                        component="img"
                        alt="Additional"
                        image={imageUrl}
                        style={{ height: "30em" }}
                      />
                    </Box>
                  ))}
              </Carousel>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.grid}>
              <Typography gutterBottom variant="h5">
                {data.name}
              </Typography>
              <Typography gutterBottom variant="body1" color="textSecondary" component="p" style={{ margin: "20px 0", whiteSpace: "pre-wrap" }}>
                {data.description}
              </Typography>
              <Box style={{ marginBottom: "20px" }}>
                <Typography gutterBottom variant="h6">
                  Category:
                </Typography>
                <Typography gutterBottom variant="body1" color="textSecondary" component="p">
                  {data.category}
                </Typography>
              </Box>
              <Box style={{ marginBottom: "20px" }}>
                <Typography gutterBottom variant="h6">
                  School Years:
                </Typography>
                <Typography gutterBottom variant="body1" color="textSecondary" component="p">
                  {data.ageGroup === "university" ? "University" : `${data.ageGroup} Years`}
                </Typography>
              </Box>
              <Box style={{ marginBottom: "20px", textAlign: "center" }}>
                <Button size="large"
                  endIcon={!isAvailable ? <LockIcon /> : void 0}
                  variant="contained" color="secondary"
                  className={classes.button}
                  onClick={() => history.push(isAvailable ? `/store/worksheet/${id}/checkout` : `/subscribe`)}
                >
                  {isAvailable ? "Add Worksheet" : `Pro Only`}
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box>
            <Typography component="h1" variant="h5" color="textSecondary" className={classes.title}>
              You might also like
            </Typography>
            <Grid container spacing={2}>
              {
                worksheets.map(({ id, name, description, price, coverImage, ageGroup }) => {

                  const isOtherTemplatePurchaseable = isPremium || price === 0
                  return (
                    <Grid item xs={12} sm={4} className={classes.grid} key={id}>
                      <Card className={classes.root}>
                        <CardActionArea disabled>
                          <CardMedia
                            component="img"
                            alt={name}
                            image={coverImage}
                            title={name}
                            style={{ width: "inherit", height: "inherit" }}
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h6" component="h2" className={classes.name}>
                              {name}
                            </Typography>
                            <Typography gutterBottom variant="body1" color="textSecondary" component="h2" className={classes.category}>
                              School Years: {ageGroup}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
                              {description}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions style={{ justifyContent: "space-evenly", marginBottom: "10px" }}>
                          <Button
                            size="medium"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => history.push(`/store/worksheet/${id}`)}>
                            More Details
                          </Button>
                          <Button size="medium" endIcon={!isOtherTemplatePurchaseable ? <LockIcon /> : void 0} variant="contained" color="secondary" className={classes.button} onClick={() => history.push(isOtherTemplatePurchaseable ? `/store/worksheet/${id}/checkout` : `/subscribe`)}>
                            {isOtherTemplatePurchaseable ? "Add Worksheet" : `Pro Only`}
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  )
                })
              }
            </Grid>
          </Box>
        </Container>
      )
  )
};

export default StoreWorksheetPage;