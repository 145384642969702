import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  Typography,
  Container,
  Box,
  Modal,
  TextField,
  Button,
  CircularProgress
} from "@material-ui/core";
import Loading from "../../../components/Loading";

import { getQuiz } from "../../../actions/worksheet";

const useStyles = makeStyles((theme) => ({
  main: {
    textAlign: "center",
    marginBottom: 20,
  },
  studentDetails: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-evenly",
    },
  },
  name: {
    fontWeight: "bold",
    display: "inline-block",
    marginTop: 5,
    backgroundColor: "#F1F1F1",
    borderRadius: 5,
    padding: 8,
  },
  nameText: {
    color: theme.palette.secondary.main,
    marginLeft: 5,
    fontWeight: "bold",
  },
  body: {
    borderTop: "1px solid #dedcdc",
    padding: "20px 0",
    marginBottom: 50,
  },
  content: {
    backgroundColor: "#fff",
    boxShadow: "0px 2px 4px #dadada",
    padding: "40px 30px",
    borderRadius: 5,
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    width: 150,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    width: "100%",
    height: "100%",
  },
  modalBody: {
    width: "40%",
    height: "30%",
    padding: 50,
    boxShadow: "0px 2px 4px #dadada",
    backgroundColor: "#fff",
    borderRadius: 5,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

const ViewQuizPage = (props) => {
  const { worksheetId, quizId, studentName } = props.match.params;
  const classes = useStyles();
  const history = useHistory();

  const { worksheetName, worksheetCoverImage } = useSelector(
    (state) => state.tutor.tutor.worksheets
  ).filter((worksheet) => worksheet.id === worksheetId)[0];

  const [quiz, setQuiz] = useState([]);

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await getQuiz(quizId);
      if (!res) {
        return;
      }
      const data = JSON.parse(res).map((question) => ({
        ...question
      }));
      setQuiz(data);
      setLoading(false);
    })();
  }, [worksheetId, quizId]);

  const handleSubmit = async (e) => {
    // do nothing
    history.goBack();
  };

  return loading ? (
    <Loading />
  ) : (
    <Container>
      <CssBaseline />
      <Box className={classes.main}>
        <img
          style={{ borderRadius: "5px" }}
          width="100px"
          height="100px"
          src={worksheetCoverImage}
          alt="Cover"
        />
        <Typography
          color="textSecondary"
          style={{ fontWeight: "bold", fontSize: 20, marginTop: 5 }}
        >
          {worksheetName}
        </Typography>
        <Box className={classes.studentDetails}>
          <Typography color="textSecondary" className={classes.name}>
            Student Name:{" "}
            <span className={classes.nameText}>{studentName}</span>
          </Typography>
        </Box>
      </Box>
      <Box className={classes.body}>
        <Box className={classes.content}>
          {quiz.map((entry, i) => (
            <Box style={{ marginBottom: 30 }} key={i}>
              <Typography color="textSecondary" style={{ marginBottom: 5, fontWeight: 'bold' }}>
                Question: {entry.question}
              </Typography>
              <Typography color="textSecondary" style={{ marginBottom: 5 }}>
                Answer: {entry.answer}
              </Typography>
            </Box>
          ))}
          <Box style={{ marginBottom: 30, textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubmit}
            >
              Back
            </Button>
          </Box>
        </Box>
      </Box>
      <Modal
        open={open}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
        hideBackdrop
      >
        <Box className={classes.modal}>
          <Box className={classes.modalBody}>
            <Box alignSelf="center">
              <CircularProgress size={80} className={classes.icon} />
              <Typography
                color="textSecondary"
                style={{ marginTop: 20, fontSize: 24 }}
              >
                Processing, please wait.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default ViewQuizPage;
