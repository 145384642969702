import axios from "axios";
import { ADMIN_SERVICE_URL } from "./constant";

export const getParentEvent = async () => {
    try {
    //   const session = await Auth.currentSession();
      const res = await axios.get(`${ADMIN_SERVICE_URL}/event/parent`);
      return res.data;
    } catch (e) {
      console.log("error", e);
    }
  }