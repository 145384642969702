import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Box,
  FormControl,
  Select,
  TextField
} from '@material-ui/core';

import filteredWorksheets from "../selectors/filteredWorksheets";
import LockIcon from "@material-ui/icons/Lock";
import { checkSubscriptionType } from "../actions/tutor";
import { useSelector } from "react-redux";
import { schoolYearSortAlgorithm } from "../selectors/schoolYearSortAlgorithm";


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  grid: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    display: "flex",
    borderBottom: "1px solid #BDBDBD",
    padding: "2px 0",
    margin: "30px 0",
    justifyContent: "space-between"
  },
  name: {
    display: "-webkit-box",
    height: "3em",
    lineHeight: "1em",
    overflow: "hidden",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
  },
  description: {
    overflow: "hidden",
    WebkitLineClamp: 3,
    display: "-webkit-box",
    boxOrient: "vertical",

  },
  button: {
    color: "white",
    textTransform: "none",
    overflow: "hidden",
    fontSize: "0.82rem",
  },
  category: {
    color: theme.palette.primary.main,
    display: "inline-block",
  },
  game: {
    color: theme.palette.secondary.main,
    display: "inline-block",
  },
  selectRoot: {
    padding: 6,
    '&:focus': {
      backgroundColor: 'inherit'
    }
  }
}));



const Cards = ({ templates, type, title }) => {
  const classes = useStyles();
  const history = useHistory();
  const [filteredAge, setFilteredAge] = useState("");
  const [filteredCategory, setFilteredCategory] = useState("");
  const [cards, setCards] = useState([]);
  const tutor = useSelector((state) => state.tutor.tutor);

  const [searchText, setSearchText] = useState("");

  useEffect(() => {

    var temps = templates;
    if (temps) {
      temps = temps.filter(template => tutor.schoolYear.includes(template.ageGroup));
      if (filteredAge) {
        temps = temps.filter((worksheet) => filteredAge === "" ? true : worksheet.ageGroup.toLowerCase() === filteredAge);
      }
      if (filteredCategory) {
        temps = temps.filter(template => template.category === filteredCategory);
      }
      if (searchText) {
        temps = temps.filter(template => template.name.toLowerCase().includes(searchText.toLowerCase()) || template.description.toLowerCase().includes(searchText.toLowerCase()));
      }
  
      setCards(temps
        .sort((a, b) => a.price - b.price));
    }
    
  }, [
    templates,
    tutor,
    filteredCategory,
    filteredAge,
    searchText
  ]);

  const getCategories = (uniqueValues, currentValue) => {
    const category = currentValue.category;

    if (!uniqueValues.includes(category)) {
      uniqueValues.push(category);
    }

    return uniqueValues;
  };

  const isPremium = checkSubscriptionType(tutor?.subscriptionType);

  const isAvailable = (price) => {
    if (price === 0) return true;
    return price !== 0 && isPremium;
  }

  return (
    <Box>
      {templates?.length > 0 &&
        <Box>
          <Typography component="h1" variant="h4" color="textSecondary" style={{ textAlign: "center" }}>
            {title}
          </Typography>
          <Box className={classes.title}>

            <Box
              sx={{
                flexDirection: { xs: "column", sm: "row" },
                display: "flex",
                width: "100%",
                padding: "8px 0"
              }}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                style={{ display: "flex", alignItems: "center", padding: "6px 0" }}
                sx={{ flexDirection: { xs: "column", lg: "row" } }}
              >
                <Box
                  sx={{ display: { xs: "none", md: "block" } }}
                  style={{ marginRight: 6, padding: "6px 0" }}
                >
                  <Typography variant="body1" color="textSecondary">
                    Filter By Category
                  </Typography>
                </Box>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    native
                    value={filteredCategory}
                    onChange={(e) => setFilteredCategory(e.target.value)}
                    classes={{ root: classes.selectRoot }}
                    style={{ width: 130, color: "#757575", height: 40 }}
                  >
                    <option aria-label="None" value="">All</option>
                    {templates.reduce(getCategories, []).map((category) => (
                      <option value={category} key={category}>{category}</option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box
                style={{ display: "flex", alignItems: "center", padding: "6px 0"  }}
                sx={{ flexDirection: { xs: "column", lg: "row" } }}
              >
                <Box
                  sx={{ display: { xs: "none", md: "block" } }}
                  style={{ marginRight: 6, padding: "6px 0", marginLeft: 24 }}
                >
                  <Typography variant="body1" color="textSecondary" >
                    Filter By School Year
                  </Typography>
                </Box>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    native
                    value={filteredAge}
                    onChange={(e) => setFilteredAge(e.target.value)}
                    classes={{ root: classes.selectRoot }}
                    style={{ width: 130, color: "#757575", height: 40 }}
                  >
                    {tutor.schoolYear.length > 1 && <option aria-label="None" value="">All</option>}
                    {tutor.schoolYear.sort(schoolYearSortAlgorithm).map((year) => (
                      <option value={year} key={year}>{year}</option>

                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 6,
                  marginTop: "auto", 
                  padding: "6px 0" 
                }}
                sx={{
                  marginLeft: {
                    xs: 0,
                    sm: "auto"
                  }
                }}
              >
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="outlined-basic"
                    label="Search"
                    variant="outlined"
                    value={searchText}
                    size="small"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </FormControl>
              </Box>

            </Box>
          </Box>
          <Grid container spacing={2}>
            {
              tutor.schoolYear.length < 1 ? (
                <Typography variant="h4" color="textSecondary" style={{ textAlign: "center", width: "100%" }}>
                  Please select your school year in the profile page to view relevant worksheets
                </Typography>
              ) : cards.map(({ id, name, description, price, coverImage, ageGroup, templateId, game }) => (
                <Grid item xs={12} sm={4} className={classes.grid} key={id + " " + templateId}>
                  <Card className={classes.root}>
                    <CardActionArea disabled>
                      <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        image={coverImage}
                        title="Contemplative Reptile"
                        style={{ width: "inherit", height: "inherit" }}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="h2" className={classes.name}>
                          {name}
                        </Typography>
                        <div>
                          {game ?
                            <Typography gutterBottom variant="body1" color="textSecondary" component="h2" className={classes.game}>
                              Game: {game}
                            </Typography>
                            : void 0
                          }
                        </div>

                        <div>
                          <Typography gutterBottom variant="body1" color="textSecondary" component="h2" className={classes.category}>
                            {ageGroup === 'university' ? void 0 : `Grades: `}
                            {ageGroup}
                          </Typography>
                        </div>

                        <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
                          {description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions style={{ justifyContent: "space-evenly", marginBottom: "10px" }}>
                      <Button size="medium" variant="contained" color="primary" className={classes.button} onClick={() => history.push(`/store/${type}/${id}`)}>
                        More Details
                      </Button>
                      <Button
                        size="medium"
                        endIcon={isAvailable(price) ? void 0 : <LockIcon />}
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={() =>
                          history.push(isAvailable(price) ? `/store/${type}/${id}/checkout` : `/subscribe`)
                        }>
                        {isAvailable(price) ? "Add WorkSheet" : `Subscribe`}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            }
          </Grid>
        </Box>
      }
    </Box>
  )
};

export default Cards;