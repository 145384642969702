import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  Modal,
  CircularProgress
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    width: "100%",
    height: "100%",
  },
  modalBody: {
    padding: 100, 
    boxShadow: "0px 2px 4px #dadada", 
    backgroundColor: "#fff", 
    borderRadius: 5, 
    textAlign: "center"
  },
  icon: {
    color: theme.palette.primary.main
  },
}));

const ModalComponent = ({ open }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
      hideBackdrop
    >
      <Box className={classes.modal}>
        <Box className={classes.modalBody}>
          <CircularProgress size={80} className={classes.icon} />
          <Typography
            color="textSecondary"
            style={{ marginTop: 20, fontSize: 24 }}
          >
            Processing, please wait.
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
};

export default ModalComponent;