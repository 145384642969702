import axios from "axios";

import { TUTOR_SERVICE_URL } from "./constant";

export const getGames = async () => {
  try {
    const { data } = await axios.get(`${TUTOR_SERVICE_URL}/games`);
    return data.data || {sortedGames: []};
  } catch (e) {
    console.log("error", e);
  }
};

export const getGame = async (id) => {
  try {
    const { data } = await axios.get(`${TUTOR_SERVICE_URL}/game?id=${id}`);
    return data.data;
  } catch (e) {
    console.log("error", e);
  }
};
